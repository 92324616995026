.page-category-motif, .page-category-item, .page-category-neko {
  background-color: #F4F1ED;
}

.SM-categoryMain {
  //background-color: #F4F1ED;
  .SM-main {
    @include vw(40, padding-top);
    > section {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .SM-categoryMain_title {
    @include vw(190, width);
  }
  .SM-categoryList {
    display: flex;
    flex-wrap: wrap;
    @include vw((40, 4, 0), padding);
    li {
      width: 33.3333%;
      text-align: center;
      @include vw(15, padding);
      a {
        display: block;
        > p {
          //font-family: Noto Sans CJK JP;
          font-size: 14px;
          @include vw(9, padding-top);
        }

      }
    }
  }
}

@include mq() {
  .SM-categoryMain {
    .SM-main {
      padding-top: 90px;
    .SM-categoryMain_title {
      width: 253px;
    }
    .SM-categoryList {
      width: 980px;
      padding: 57px 0 0;
      li {
        width: 20%;
        padding: 14px;
        a {
          > p {
            padding-top: 8px;
          }
        }
      }
    }
    }
  }
}


.SM-categoryLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  //background-color: #F4F1ED;
  @include vw(60, padding-top);
  a {
    display: block;
    position: relative;
    &:not(:first-child) {
      @include vw(28, margin-top);
    }
    span {
      position: absolute;
      bottom: 0;
      margin: auto;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 16px;
      @include vw(14);
      //font-family: Noto Sans CJK JP;
      //font-weight: bold;
    }

    //&:hover {
    //  img {
    //    &:nth-child(1) {
    //      opacity: 1;
    //      transition: 0.2s opacity;
    //      z-index: 1;
    //
    //    }
    //    &:nth-child(2) {
    //      opacity: 0;
    //      //transition-delay: 0s;
    //      transition: 0.6s opacity;
    //      z-index: 2;
    //
    //    }
    //  }
    //}

    img {
      position: absolute;
      top: 0;
      left: 0;
      &:nth-child(1) {
        opacity: 0;
        transition: 0.6s opacity;
        z-index: 2;
      }
      &:nth-child(2) {
        transition: 0.2s opacity;
        z-index: 1;

      }

    }
  }
  .SM-categoryLink_motif {
    @include vw(240, width);
    @include vw(240, height);
  }
  .SM-categoryLink_item {
    @include vw(224, width);
    @include vw(224, height);
  }
  .SM-categoryLink_neko {
    @include vw(214, width);
    @include vw(214, height);
  }
  @include mq() {
    a {
      //span {
      //  font-size: 16px;
      //}
    }
  }
}

@include mq() {
  .SM-categoryMain {
    .SM-main {
      .SM-categoryLink {
        flex-direction: row;
        justify-content: center;
        width: 980px;
        margin: 0 auto;
        padding: 52px 0;

        a {
          margin: 20px;
          &:hover {
            img {
              &:nth-child(1) {
                opacity: 1;
                transition: 0.2s opacity;
                z-index: 1;

              }
              &:nth-child(2) {
                opacity: 0;
                //transition-delay: 0s;
                transition: 0.6s opacity;
                z-index: 2;

              }
            }
          }
          &:not(:first-child) {
            margin: 20px;
          }
          span {
            font-size: 16px;
          }
        }

        .SM-categoryLink_motif {
          width: 262px;
          height: 262px;
        }

        .SM-categoryLink_item {
          width: 262px;
          height: 262px;
        }

        .SM-categoryLink_neko {
          width: 262px;
          height: 262px;
        }
      }
    }
  }
}