//body:not(.home) {
//  .SM-header {
//    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.04);
//  }
//}

.SM-header {
  display: flex;
  //align-items: center;
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 64px;
  padding: 0;
  background-color: #FFFFFF;
  top: 0;
  //box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.04);
  .SM-l_header {
    padding: 0;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.04);

    .SM-header_wrapper {
      display: flex;
      justify-content: space-between;
      padding: 0;
      width: 100%;
      height: 100%;

      @include mq() {
        max-width: 1200px;
        margin: 0 auto;
      }
    }
  }

  .SM-headerMain_mid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    //width: 100%;
    .SM-headerMain_logo {
      display: block;
      width: 142px;
      height: 49px;
      @include mq() {
        width: 188px;
        height: 65px;
      }
    }
  }

  .header-bottom {
    display: none;
  }

  .search-modal {
    top: 0;
    opacity: 1;
    z-index: -1;
    @include mq() {
      z-index: 3;
    }
    //transition: top 1s;
    &.active {
      top: 64px;
      //transition: top 1s;

    }
  }
}

.SM-spMenuBtn,
.SM-spMenuBtn span {
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box;
}
.SM-spMenuBtn {
  transform: scale(0.3);
  position: relative;
  width: 50px;
  height: 44px;
  background: none;
  border: none;
  appearance: none;
  cursor: pointer;
  padding: 100px;
  > div {
    position: relative;
    width: 50px;
    height: 44px;
    margin: -25px;
  }
}
.SM-spMenuBtn span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 6px;
  background-color: #000000;
  //border-radius: 4px;
}
.SM-spMenuBtn span:nth-of-type(1) {
  top: -2px;
}
.SM-spMenuBtn span:nth-of-type(2) {
  top: 18px;
}
.SM-spMenuBtn span:nth-of-type(3) {
  bottom: 0;
}


.SM-spMenuBtn_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
}
@include mq() {
  .SM-spMenuBtn_wrapper {
    display: none;
  }
}


.SM-spSearchBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  background-color: transparent !important;
}

@include mq() {
  .SM-spSearchBtn {
    display: none;
  }
}


.SM-pcMenu {
  display: none;
}

@include mq() {
  //body.page-about {
  //  .SM-pcMenuList {
  //    &:nth-child(2) {
  //
  //    }
  //  }
  //}
  .SM-pcMenu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    font-size: 14px;
    nav {
      display: flex;
      align-items: center;
      height: 50px;

    }
    li {
      //width: 150px;
      display: flex;
      justify-content: center;
      padding: 0 20px;
      &.active {
        a {
          &:after {
            content: "";
            position: absolute;
            transform: translate(-50%, -5px);
            left: 50%;
            //top: 0;
            //left: 0;
            width: 140px;
            height: 55px;
            background-image: url("/wp-content/themes/suimin-v1/img/common/header_nav_active_pc.png");
            background-repeat: no-repeat;
            background-size: 140px 42px;
            background-position: center 11px;
            opacity: 1;
            //transition: opacity .6s;
          }
        }
      }

      a {
        display: block;
        letter-spacing: 4px;

        position: relative;
        &:after {
          content: "";
          position: absolute;
          transform: translate(-50%, -5px);
          left: 50%;
          top: 0;
          //left: 0;
          width: 140px;
          height: 55px;
          background-image: url("/wp-content/themes/suimin-v1/img/common/header_nav_active_pc.png");
          background-repeat: no-repeat;
          background-size: 140px 42px;
          background-position: center 11px;
          opacity: 0;
          transition: opacity .6s;
        }

        &:hover {
          &:after {
            opacity: 1;
          }
        }
      }
      &:last-child {
        font-size: 14px;
        padding-top: 4px;
        a {
          &:after {
            transform: translate(-50%, -9px);
          }
        }
      }
    }
  }
}

.SM-headerSearch {
  .SM-l_headerSearch {
    display: flex;
  }
  .SM-headerSearch_input {
    width: 100%;
    //width: 100%;
    padding: 20px 5px;
    margin-left: 45px;
    @include mq() {
      padding: 20px;
      margin: 0;
      width: 190px;
    }
  }
  .SM-headerSearch_searchBtn {
    position: absolute;
    display: block;
    top: 26px;
    left: 16px;
    @include mq() {
      top: 4px;
      right: 4px;
      left: auto;
    }
  }
}
@include mq() {
  .SM-stt_header_off {
    .SM-header {
      top: -115px;
    }
  }
  .SM-header {
    transition: top .5s;
    top: 0;
    .SM-l_header {
      height: 115px;
    }
  }
  .SM-headerSearch {
    display: flex !important;
    align-items: center;
    position: static !important;
    width: 224px !important;
    margin: 0 25px 0 40px;
    .SM-headerSearch_input {
      padding: 0;
    }
    .SM-l_headerSearch {
      border-bottom: 1px solid #000000;
      width: 224px !important;
    }
     > div {
       transform: none;
       height: 30px;
     }
    button {
      display: none !important;
    }
    input {
      font-size: 14px;
      width: 200px;
    }
  }
}

@include mq() {
  .SM-pcMenuList {
    display: flex;
    height: 100%;
    font-family: 'Shippori Mincho B1', serif;
    font-size: 18px;
    li {
      a {
        display: flex;
        align-items: center;
      }

    }
  }
}


//body.SM-stt_loaded {
//body.SM-stt_loading {
  //.SM-spMenuBtn {
  //  * {
  //    animation: none;
  //  }
  //}
  //}
//body.SM-stt_loading {
//  .SM-spMenuBtn {
//    opacity: 0;
//  }
//}
//body.SM-stt_loaded {
//  .SM-spMenuBtn {
//    transition: none;
//  }
//}
//
//body.SM-stt_loaded {
//  .SM-spMenuBtn span:nth-of-type(1) {
//    animation-delay: 0s;
//  }
//}
  .SM-spMenuBtn span:nth-of-type(1) {
    //animation: menu-bar01 .75s forwards;
    transform: translateY(0) rotate(0);
  }

  @keyframes menu-bar01 {
    0% {
      transform: translateY(20px) rotate(45deg);
    }
    50% {
      transform: translateY(20px) rotate(0);
    }
    100% {
      transform: translateY(0) rotate(0);
    }
  }

  .SM-spMenuBtn span:nth-of-type(2) {
    //transition: all .25s .25s;
    transition: all .25s;
    opacity: 1;
  }

  .SM-spMenuBtn span:nth-of-type(3) {
    //animation: menu-bar02 .75s forwards;
    transform: translateY(0) rotate(0);
  }

  @keyframes menu-bar02 {
    0% {
      transform: translateY(-20px) rotate(-45deg);
    }
    50% {
      transform: translateY(-20px) rotate(0);
    }
    100% {
      transform: translateY(0) rotate(0);
    }
  }

  .SM-spMenuBtn.active span:nth-of-type(1) {
    //animation: active-menu-bar01 .75s forwards;
    transform: translateY(20px) rotate(45deg);
  }

  @keyframes active-menu-bar01 {
    0% {
      transform: translateY(0) rotate(0);
    }
    50% {
      transform: translateY(20px) rotate(0);
    }
    100% {
      transform: translateY(20px) rotate(45deg);
    }
  }

  .SM-spMenuBtn.active span:nth-of-type(2) {
    opacity: 0;
  }

  .SM-spMenuBtn.active span:nth-of-type(3) {
    //animation: active-menu-bar03 .75s forwards;
    transform: translateY(-20px) rotate(-45deg);
  }

  @keyframes active-menu-bar03 {
    0% {
      transform: translateY(0) rotate(0);
    }
    50% {
      transform: translateY(-20px) rotate(0);
    }
    100% {
      transform: translateY(-20px) rotate(-45deg);
    }
  }
//}

//.search-modal-inner {
//  transition: transform 1s linear, box-shadow 0.15s linear
//}
//
//.search-modal.active .search-modal-inner {
//  transition: transform 1s ease-in-out, box-shadow 1s 0.25s linear
//}

