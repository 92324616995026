$breakpoints: (
    'pc': 'screen and (min-width: 980px)',
    'tab': 'screen and (min-width: 480px)',
    'iframe': 'screen and (min-width: 948px)',
    //'sp': 'screen and (min-width: 480)',

    //'xxl': 'screen and (min-width: 1920px)',
    //'xl': 'screen and (min-width: 1200px)',
    //'l': 'screen and (min-width: 1024px)',
    //'s': 'screen and (min-width: 992px)',
    //'xs': 'screen and (min-width: 720px)',
    //'xxs': 'screen and (min-width: 480px)',
) !default;
@mixin mq($breakpoint: pc) {
    @media #{map-get($breakpoints, $breakpoint)} {
        @content;
    }
}
@mixin ls() {
    @media all and (orientation: landscape) {
        @content;
    }
}
