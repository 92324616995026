.SM-contentArea.SM-searchList {
 .SM-searchList_title {
   font-size: 14px;
   padding: 0;
   //padding: 32px 16px 0;
   text-align: left;
   width: 100%;
   font-weight: normal;
 }
}
.SM-searchList_title {

}

@include mq() {
  .SM-contentArea.SM-searchList {
    padding-top: 195px;
    .SM-searchList_title {
      width: 980px;
      padding: 0;
    }
  }
}