@charset "UTF-8";
@import url(https://fonts.googleapis.com/earlyaccess/notosansjp.css);
input,
button,
textarea,
select {
  /* デフォルトスタイルをリセット */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* font-familyを継承しないので、継承させる */
  font-family: inherit;
  /* iOSの角丸をリセット */
  border-radius: 0;
  /* box-size */
  box-sizing: border-box;
  /* 文字の大きさ iOSでズームさせないために16px以上を指定 */
  font-size: 16px;
  /* 文字色を親から継承 */
  color: inherit;
  border: 0;
}

input:focus,
button:focus,
textarea:focus,
select:focus {
  outline: none;
}

label {
  /* iOSでのlabelとinput,select,textareaの関連付け */
  cursor: pointer;
}

/* スピンボタン非表示 chrome,safari */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button,
input[type="month"]::-webkit-outer-spin-button,
input[type="month"]::-webkit-inner-spin-button,
input[type="datetime-local"]::-webkit-outer-spin-button,
input[type="datetime-local"]::-webkit-inner-spin-button,
input[type="week"]::-webkit-outer-spin-button,
input[type="week"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button,
input[type="time"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-inner-spin-button {
  /*-webkit-appearance: none;
  margin: 0;*/
  display: none;
}

/* スピンボタン非表示(firefox) */
input[type="number"],
input[type="month"],
input[type="datetime-local"],
input[type="week"],
input[type="time"],
input[type="date"] {
  -moz-appearance: textfield;
}

/* クリアボタン非表示 */
input[type="date"]::-webkit-clear-button,
input[type="month"]::-webkit-clear-button,
input[type="datetime-local"]::-webkit-clear-button,
input[type="time"]::-webkit-clear-button,
input[type="week"]::-webkit-clear-button {
  -webkit-appearance: none;
}

h1, h2, h3, h4 {
  font-weight: normal;
}

.SM-header {
  display: flex;
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 64px;
  padding: 0;
  background-color: #FFFFFF;
  top: 0;
}

.SM-header .SM-l_header {
  padding: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.04);
}

.SM-header .SM-l_header .SM-header_wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 980px) {
  .SM-header .SM-l_header .SM-header_wrapper {
    max-width: 1200px;
    margin: 0 auto;
  }
}

.SM-header .SM-headerMain_mid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SM-header .SM-headerMain_mid .SM-headerMain_logo {
  display: block;
  width: 142px;
  height: 49px;
}

@media screen and (min-width: 980px) {
  .SM-header .SM-headerMain_mid .SM-headerMain_logo {
    width: 188px;
    height: 65px;
  }
}

.SM-header .header-bottom {
  display: none;
}

.SM-header .search-modal {
  top: 0;
  opacity: 1;
  z-index: -1;
}

@media screen and (min-width: 980px) {
  .SM-header .search-modal {
    z-index: 3;
  }
}

.SM-header .search-modal.active {
  top: 64px;
}

.SM-spMenuBtn,
.SM-spMenuBtn span {
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box;
}

.SM-spMenuBtn {
  transform: scale(0.3);
  position: relative;
  width: 50px;
  height: 44px;
  background: none;
  border: none;
  appearance: none;
  cursor: pointer;
  padding: 100px;
}

.SM-spMenuBtn > div {
  position: relative;
  width: 50px;
  height: 44px;
  margin: -25px;
}

.SM-spMenuBtn span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 6px;
  background-color: #000000;
}

.SM-spMenuBtn span:nth-of-type(1) {
  top: -2px;
}

.SM-spMenuBtn span:nth-of-type(2) {
  top: 18px;
}

.SM-spMenuBtn span:nth-of-type(3) {
  bottom: 0;
}

.SM-spMenuBtn_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
}

@media screen and (min-width: 980px) {
  .SM-spMenuBtn_wrapper {
    display: none;
  }
}

.SM-spSearchBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  background-color: transparent !important;
}

@media screen and (min-width: 980px) {
  .SM-spSearchBtn {
    display: none;
  }
}

.SM-pcMenu {
  display: none;
}

@media screen and (min-width: 980px) {
  .SM-pcMenu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    font-size: 14px;
  }
  .SM-pcMenu nav {
    display: flex;
    align-items: center;
    height: 50px;
  }
  .SM-pcMenu li {
    display: flex;
    justify-content: center;
    padding: 0 20px;
  }
  .SM-pcMenu li.active a:after {
    content: "";
    position: absolute;
    transform: translate(-50%, -5px);
    left: 50%;
    width: 140px;
    height: 55px;
    background-image: url("/wp-content/themes/suimin-v1/img/common/header_nav_active_pc.png");
    background-repeat: no-repeat;
    background-size: 140px 42px;
    background-position: center 11px;
    opacity: 1;
  }
  .SM-pcMenu li a {
    display: block;
    letter-spacing: 4px;
    position: relative;
  }
  .SM-pcMenu li a:after {
    content: "";
    position: absolute;
    transform: translate(-50%, -5px);
    left: 50%;
    top: 0;
    width: 140px;
    height: 55px;
    background-image: url("/wp-content/themes/suimin-v1/img/common/header_nav_active_pc.png");
    background-repeat: no-repeat;
    background-size: 140px 42px;
    background-position: center 11px;
    opacity: 0;
    transition: opacity .6s;
  }
  .SM-pcMenu li a:hover:after {
    opacity: 1;
  }
  .SM-pcMenu li:last-child {
    font-size: 14px;
    padding-top: 4px;
  }
  .SM-pcMenu li:last-child a:after {
    transform: translate(-50%, -9px);
  }
}

.SM-headerSearch .SM-l_headerSearch {
  display: flex;
}

.SM-headerSearch .SM-headerSearch_input {
  width: 100%;
  padding: 20px 5px;
  margin-left: 45px;
}

@media screen and (min-width: 980px) {
  .SM-headerSearch .SM-headerSearch_input {
    padding: 20px;
    margin: 0;
    width: 190px;
  }
}

.SM-headerSearch .SM-headerSearch_searchBtn {
  position: absolute;
  display: block;
  top: 26px;
  left: 16px;
}

@media screen and (min-width: 980px) {
  .SM-headerSearch .SM-headerSearch_searchBtn {
    top: 4px;
    right: 4px;
    left: auto;
  }
}

@media screen and (min-width: 980px) {
  .SM-stt_header_off .SM-header {
    top: -115px;
  }
  .SM-header {
    transition: top .5s;
    top: 0;
  }
  .SM-header .SM-l_header {
    height: 115px;
  }
  .SM-headerSearch {
    display: flex !important;
    align-items: center;
    position: static !important;
    width: 224px !important;
    margin: 0 25px 0 40px;
  }
  .SM-headerSearch .SM-headerSearch_input {
    padding: 0;
  }
  .SM-headerSearch .SM-l_headerSearch {
    border-bottom: 1px solid #000000;
    width: 224px !important;
  }
  .SM-headerSearch > div {
    transform: none;
    height: 30px;
  }
  .SM-headerSearch button {
    display: none !important;
  }
  .SM-headerSearch input {
    font-size: 14px;
    width: 200px;
  }
}

@media screen and (min-width: 980px) {
  .SM-pcMenuList {
    display: flex;
    height: 100%;
    font-family: 'Shippori Mincho B1', serif;
    font-size: 18px;
  }
  .SM-pcMenuList li a {
    display: flex;
    align-items: center;
  }
}

.SM-spMenuBtn span:nth-of-type(1) {
  transform: translateY(0) rotate(0);
}

@keyframes menu-bar01 {
  0% {
    transform: translateY(20px) rotate(45deg);
  }
  50% {
    transform: translateY(20px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}

.SM-spMenuBtn span:nth-of-type(2) {
  transition: all .25s;
  opacity: 1;
}

.SM-spMenuBtn span:nth-of-type(3) {
  transform: translateY(0) rotate(0);
}

@keyframes menu-bar02 {
  0% {
    transform: translateY(-20px) rotate(-45deg);
  }
  50% {
    transform: translateY(-20px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}

.SM-spMenuBtn.active span:nth-of-type(1) {
  transform: translateY(20px) rotate(45deg);
}

@keyframes active-menu-bar01 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(20px) rotate(0);
  }
  100% {
    transform: translateY(20px) rotate(45deg);
  }
}

.SM-spMenuBtn.active span:nth-of-type(2) {
  opacity: 0;
}

.SM-spMenuBtn.active span:nth-of-type(3) {
  transform: translateY(-20px) rotate(-45deg);
}

@keyframes active-menu-bar03 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(-20px) rotate(0);
  }
  100% {
    transform: translateY(-20px) rotate(-45deg);
  }
}

@media screen and (min-width: 980px) {
  .SM-stt_header_off .SM-drawerMenu {
    top: 0;
  }
}

.SM-drawerMenu {
  top: 64px;
}

@media screen and (min-width: 980px) {
  .SM-drawerMenu {
    top: 115px;
    transition: top .5s;
    position: fixed;
    width: 100%;
  }
}

.SM-drawerMenu .SM-l_drawerMenu {
  width: 100%;
  margin: 0;
}

.SM-drawerMenu .SM-drawerMenu_static li {
  display: flex;
  height: 60px;
  border-color: #F4F1ED;
}

.SM-drawerMenu .SM-drawerMenu_static li a {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  text-decoration: none;
  padding: 0 16px;
  color: #000000;
}

@media screen and (min-width: 980px) {
  .SM-drawerMenu {
    left: auto;
    right: auto;
    position: fixed;
    overflow: hidden;
    height: 80px;
    width: 100%;
    justify-content: center;
    background-color: transparent !important;
  }
  .SM-drawerMenu .SM-drawerCategoryNav {
    display: flex;
    justify-content: center;
    background-color: #F4F1ED;
  }
  .SM-drawerMenu .SM-menuNav {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 80px;
  }
  .SM-drawerMenu .SM-menuNav > div {
    width: 980px;
  }
  .SM-drawerMenu .SM-l_drawerMenu {
    overflow: hidden;
  }
  .SM-drawerMenu > div.menu-modal-inner {
    opacity: 1;
    width: 100%;
    background-color: transparent;
    transform: none;
  }
  .SM-drawerMenu > div.menu-modal-inner > .menu-wrapper {
    background-color: transparent;
  }
  .SM-drawerMenu > div.menu-modal-inner > div.SM-l_drawerMenu {
    width: 100%;
  }
  .SM-drawerMenu dl {
    display: flex;
    width: 100%;
  }
  .SM-drawerMenu dl dt {
    position: relative;
    width: 326.6666px;
  }
  .SM-drawerMenu dl dt a {
    justify-content: center;
    letter-spacing: 4px;
  }
  .SM-drawerMenu dl dt:nth-child(-n+4):after {
    position: absolute;
    top: 28px;
    right: 0;
    content: "";
    height: 24px;
    border-left: 1px solid #946C37;
  }
  .SM-drawerMenu dl dd {
    width: 100%;
    left: 0;
    justify-content: center;
    position: absolute;
    top: 80px;
  }
  .SM-drawerMenu dl dd ul {
    margin: 0 auto;
    width: 980px;
  }
  .SM-drawerMenu dl dd li {
    width: 93px !important;
    margin: 20px 7.7px !important;
  }
}

@media screen and (min-width: 980px) {
  body.page-all .SM-drawerMenu, body.category .SM-drawerMenu, body.search-results .SM-drawerMenu {
    display: flex !important;
  }
  body.SM-stt_pcCategoryNav .SM-drawerMenu {
    height: 100%;
  }
  body.SM-stt_pcCategoryNav_motif .SM-categoryNav_motif {
    color: #946c37;
  }
  body.SM-stt_pcCategoryNav_item .SM-categoryNav_item {
    color: #946c37;
  }
  body.SM-stt_pcCategoryNav_neko .SM-categoryNav_neko {
    color: #946c37;
  }
}

.SM-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #DED1C0;
  padding: 36px 0 57px;
}

.SM-footer .SM-footer_logo {
  width: 136px;
}

.SM-footer .SM-footer_snsLink {
  display: flex;
  padding-top: 13px;
}

.SM-footer .SM-footer_snsLink .SM-footer_snsLink_instagram {
  display: block;
  width: 29px;
}

.SM-footer .SM-footer_snsLink .SM-footer_snsLink_twitter {
  display: block;
  width: 35px;
  margin-left: 24px;
}

.SM-footer .SM-footer_copyright {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 12px;
  color: #676767;
  padding-top: 20px;
}

.SM-footer .SM-footer_copyright > span {
  font-size: 20px;
  vertical-align: -3px;
}

@media screen and (min-width: 980px) {
  .SM-footerCategoryNav {
    display: none;
  }
}

.SM-btn_link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 40px;
  background: #FDF0A3;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 700;
  color: #000000;
  text-decoration: none;
  letter-spacing: 0.1em;
  line-height: 1;
  transition: background-color 0.4s;
}

.SM-btn_link:hover {
  background-color: #e8cd3f;
}

@media screen and (min-width: 980px) {
  .SM-btn_link {
    width: 220px;
  }
}

.SM-pagination {
  display: flex;
  max-width: 100%;
  font-size: 16px;
  font-weight: 400;
  font-family: 游ゴシック体, YuGothic, 游ゴシック, "Yu Gothic", メイリオ, sans-serif;
  padding-top: 30px;
  padding-top: 8vw;
}

.SM-pagination > a, .SM-pagination span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  margin: 0 10px;
}

.SM-pagination .SM-pagination_move {
  background-image: url("/wp-content/themes/suimin-v1/img/common/pagination_arrow.svg");
  background-repeat: no-repeat;
  background-size: 10.5px 21px;
  background-position: center;
}

.SM-pagination .SM-pagination_prev {
  transform: rotate(180deg);
}

.SM-pagination .SM-pagination_current {
  background-color: #DED1C0;
  color: #FFFFFF;
}

.SM-pagination span.placeholder {
  display: none;
}

@media screen and (min-width: 980px) {
  .SM-pagination {
    padding-top: 80px;
  }
}

.SM-product_tile {
  display: flex;
  flex-wrap: wrap;
  padding: 0 9px;
  width: 100%;
}

.SM-product_tile li {
  position: relative;
  width: 50%;
  margin-top: 15px;
}

.SM-product_tile li a {
  display: block;
  margin: 7px;
}

.SM-product_tile li a div.SM-products_img {
  position: relative;
}

.SM-product_tile li a div.SM-products_img:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.SM-product_tile li a div.SM-products_img > div {
  display: flex;
  padding: 10px;
  padding: 2.66667vw;
  position: absolute;
  top: 0;
  left: 0;
  margin: auto;
  bottom: 0;
  right: 0;
  background-color: #F8F6F4;
}

.SM-product_tile li a div.SM-products_img img {
  object-fit: contain;
  transition: 0.4s;
}

.SM-product_tile li a .SM-products_info {
  padding-top: 10px;
  display: inline-block;
  max-width: 100%;
}

.SM-product_tile li a .SM-products_info > div {
  font-size: 13px;
  letter-spacing: 2px;
  line-height: 18px;
  margin: 2px 0;
  background-color: #ffffff;
  width: fit-content;
}

.SM-product_tile li a .SM-products_info .SM-products_title {
  overflow: hidden;
  display: inline-flex;
  width: 100%;
}

.SM-product_tile li a .SM-products_info .SM-products_title p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.SM-product_tile li a .SM-products_info .SM-products_shopName {
  color: #8E8E8E;
}

.SM-product_tile li a .SM-products_info .SM-products_shopName:before {
  display: inline-block;
  content: "";
  background-image: url("/wp-content/themes/suimin-v1/img/common/icon_external_link.png");
  background-size: 12px 12px;
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
  margin-right: 3px;
  vertical-align: -1px;
}

.SM-product_tile li a .SM-products_info .SM-products_price {
  font-size: 12px;
  font-family: "Noto Sans JP";
}

.SM-product_tile li a:hover .SM-products_img img {
  opacity: 0.7;
}

.SM-product_tile li .PM-products_mm {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 15px;
  top: 4vw;
  right: 15px;
  right: 4vw;
  font-size: 10px;
  font-size: 2.66667vw;
  color: #B69972;
  padding: 2px 4px;
  padding: 0.53333vw 1.06667vw;
  border-radius: 2px;
  border-radius: 0.53333vw;
  background-color: #FFFFFF;
  text-align: center;
  letter-spacing: 2px;
  letter-spacing: 0.53333vw;
}

@media screen and (min-width: 480px) {
  .SM-product_tile {
    display: flex;
    flex-wrap: wrap;
    padding: 0 9px;
    width: 100%;
  }
  .SM-product_tile li {
    position: relative;
    width: 33.3333%;
    margin-top: 15px;
  }
  .SM-product_tile li a {
    display: block;
    margin: 7px;
  }
  .SM-product_tile li a div.SM-products_img {
    position: relative;
  }
  .SM-product_tile li a div.SM-products_img:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
  .SM-product_tile li a div.SM-products_img > div {
    display: flex;
    padding: 10px;
    padding: 2.66667vw;
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    bottom: 0;
    right: 0;
    background-color: #F8F6F4;
  }
  .SM-product_tile li a div.SM-products_img img {
    object-fit: contain;
    transition: 0.4s;
  }
  .SM-product_tile li a:hover .SM-products_img img {
    opacity: 0.7;
  }
  .SM-product_tile li .PM-products_mm {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    top: 2.14286vw;
    right: 15px;
    right: 2.14286vw;
    font-size: 10px;
    font-size: 1.42857vw;
    color: #B69972;
    padding: 2px 4px;
    padding: 0.28571vw 0.57143vw;
    border-radius: 2px;
    border-radius: 0.28571vw;
    background-color: #FFFFFF;
    text-align: center;
    letter-spacing: 2px;
    letter-spacing: 0.28571vw;
  }
}

@media screen and (min-width: 980px) {
  .SM-product_tile {
    width: 994px;
    padding: 0;
  }
  .SM-product_tile li {
    width: 33.3333%;
  }
  .SM-product_tile li a div.SM-products_img > div {
    padding: 30px 26px 22px;
  }
  .SM-product_tile li a div.SM-products_img img {
    object-fit: contain;
    transition: 0.4s;
  }
  .SM-product_tile li .PM-products_mm {
    font-size: 11px;
    padding: 2px 4px;
    top: 12px;
    right: 12px;
    border-radius: 4px;
    letter-spacing: 3px;
    color: #A78455;
  }
}

.SM-contentArea.SM-searchList .SM-searchList_title {
  font-size: 14px;
  padding: 0;
  text-align: left;
  width: 100%;
  font-weight: normal;
}

@media screen and (min-width: 980px) {
  .SM-contentArea.SM-searchList {
    padding-top: 195px;
  }
  .SM-contentArea.SM-searchList .SM-searchList_title {
    width: 980px;
    padding: 0;
  }
}

.SM-searchHeader {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px 16px 0;
  font-size: 14px;
  font-weight: 400;
}

.SM-searchHeader .SM-searchNekoItem {
  position: relative;
  width: 240px;
  margin: 32px 0 10px;
  border-bottom: 1px solid #000000;
}

.SM-searchHeader .SM-searchNekoItem:after {
  position: absolute;
  top: 9px;
  right: 7px;
  content: "";
  background-image: url("/wp-content/themes/suimin-v1/img/common/select_arrow.svg");
  background-size: 10px 4.5px;
  width: 10px;
  height: 4.5px;
}

.SM-searchHeader .SM-searchNekoItem select {
  width: 100%;
  font-size: 14px;
  background-color: transparent;
}

@media screen and (min-width: 980px) {
  .SM-searchHeader {
    flex-direction: row;
    justify-content: space-between;
    width: 980px;
    padding: 48px 0 20px;
  }
  .SM-searchHeader .SM-searchNekoItem {
    margin: 0;
    width: 340px;
  }
  .SM-searchHeader .SM-searchNekoItem select {
    font-size: 14px;
  }
}

.SM-stt_pcCategoryNav_motif .SM-categoryNav_motif:after {
  transform: rotate(-180deg);
}

.SM-stt_pcCategoryNav_item .SM-categoryNav_item:after {
  transform: rotate(-180deg);
}

.SM-stt_pcCategoryNav_neko .SM-categoryNav_neko:after {
  transform: rotate(-180deg);
}

.SM-menuNav dl {
  margin: 0;
}

.SM-menuNav dt {
  height: 60px;
  font-size: 14px;
  font-weight: normal;
  border-top: 1px solid #F4F1ED;
  line-height: 1;
  margin-top: 0;
}

.SM-menuNav dt a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 0 16px;
}

.SM-menuNav dt a:after {
  content: "";
  background-image: url("/wp-content/themes/suimin-v1/img/common/category_menu_arrow_sp.svg");
  background-size: 12px 8px;
  width: 12px;
  height: 8px;
  position: absolute;
  top: 25px;
  right: 20px;
  transition: transform .3s;
}

@media screen and (min-width: 980px) {
  .SM-menuNav dt a:after {
    display: none;
  }
}

.SM-menuNav dd {
  display: none;
  background-color: #F4F1ED;
  margin: 0;
  line-height: 1;
}

.SM-menuNav dd ul {
  display: flex;
  flex-wrap: wrap;
  padding: 8px 0;
}

.SM-menuNav dd ul li {
  width: 50%;
  margin: 7.5px 0;
  padding: 0 16px;
}

.SM-menuNav dd ul li a {
  display: flex;
  align-items: center;
}

.SM-menuNav dd ul li a > img {
  width: 48px;
  width: 12.8vw;
}

.SM-menuNav dd ul li a > p {
  padding-left: 8px;
}

@media screen and (min-width: 980px) {
  .SM-menuNav dt {
    height: 80px;
  }
  .SM-menuNav dd {
    transition: .5s box-shadow;
  }
  .SM-menuNav dd ul {
    padding: 4px 0 30px;
  }
  .SM-menuNav dd ul li {
    margin: 0;
    padding: 0;
  }
  .SM-menuNav dd ul li a {
    flex-direction: column;
  }
  .SM-menuNav dd ul li a > p {
    padding: 3px 0 0;
    font-size: 12px;
  }
  body.SM-stt_pcCategoryNav .SM-menuNav dd {
    box-shadow: 0px 32px 30px 0px rgba(0, 0, 0, 0.16);
  }
  body.SM-stt_pcCategoryNav .SM-menuNav.SM-stt_pcCategoryNav_motif .SM-categoryNav_motif {
    color: #946C37;
    font-weight: 700;
  }
  body.SM-stt_pcCategoryNav .SM-menuNav.SM-stt_pcCategoryNav_item .SM-categoryNav_item {
    color: #946C37;
    font-weight: 700;
  }
  body.SM-stt_pcCategoryNav .SM-menuNav.SM-stt_pcCategoryNav_neko .SM-categoryNav_neko {
    color: #946C37;
    font-weight: 700;
  }
}

html {
  font-family: 游ゴシック体, YuGothic, 游ゴシック, "Yu Gothic", メイリオ, sans-serif !important;
}

html body {
  background: #ffffff;
  min-width: 300px;
  color: #000000;
}

html body a {
  text-decoration: none;
  color: #000000;
}

html body li {
  list-style: none;
}

.SM-contentArea {
  padding-top: 64px;
  margin-top: 0;
}

.SM-contentArea .SM-main > section {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Noto Sans JP";
}

.SM-contentArea .SM-main > section:last-child {
  padding-bottom: 50px;
  padding-bottom: 13.33333vw;
}

@media screen and (min-width: 980px) {
  .SM-contentArea {
    padding-top: 115px;
  }
  .SM-contentArea .SM-main > section:last-child {
    padding-bottom: 64px;
  }
}

.wpcf7-not-valid-tip {
  color: #C22727 !important;
}

.SM-404, .SM-search404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.SM-404 .SM-sorry_btns, .SM-search404 .SM-sorry_btns {
  display: flex;
  flex-direction: column;
}

.SM-404 .SM-sorry_btns a, .SM-search404 .SM-sorry_btns a {
  margin: 20px 20px;
}

@media screen and (min-width: 980px) {
  .SM-404, .SM-search404 {
    padding-bottom: 100px;
  }
  .SM-404 .SM-sorry_btns, .SM-search404 .SM-sorry_btns {
    display: flex;
    flex-direction: row;
  }
  .SM-404 .SM-sorry_btns a, .SM-search404 .SM-sorry_btns a {
    margin: 0 20px;
  }
}

.SM-sorry {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 50px;
  width: 100%;
}

.SM-sorry > p {
  font-size: 14px;
  padding-top: 20px;
}

.SM-sorry > img {
  width: 100px;
}

.page-category-motif, .page-category-item, .page-category-neko {
  background-color: #F4F1ED;
}

.SM-categoryMain .SM-main {
  padding-top: 40px;
  padding-top: 10.66667vw;
}

.SM-categoryMain .SM-main > section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SM-categoryMain .SM-categoryMain_title {
  width: 190px;
  width: 50.66667vw;
}

.SM-categoryMain .SM-categoryList {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 4px 0;
  padding: 10.66667vw 1.06667vw 0;
}

.SM-categoryMain .SM-categoryList li {
  width: 33.3333%;
  text-align: center;
  padding: 15px;
  padding: 4vw;
}

.SM-categoryMain .SM-categoryList li a {
  display: block;
}

.SM-categoryMain .SM-categoryList li a > p {
  font-size: 14px;
  padding-top: 9px;
  padding-top: 2.4vw;
}

@media screen and (min-width: 980px) {
  .SM-categoryMain .SM-main {
    padding-top: 90px;
  }
  .SM-categoryMain .SM-main .SM-categoryMain_title {
    width: 253px;
  }
  .SM-categoryMain .SM-main .SM-categoryList {
    width: 980px;
    padding: 57px 0 0;
  }
  .SM-categoryMain .SM-main .SM-categoryList li {
    width: 20%;
    padding: 14px;
  }
  .SM-categoryMain .SM-main .SM-categoryList li a > p {
    padding-top: 8px;
  }
}

.SM-categoryLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  padding-top: 16vw;
}

.SM-categoryLink a {
  display: block;
  position: relative;
}

.SM-categoryLink a:not(:first-child) {
  margin-top: 28px;
  margin-top: 7.46667vw;
}

.SM-categoryLink a span {
  position: absolute;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 16px;
  font-size: 14px;
  font-size: 3.73333vw;
}

.SM-categoryLink a img {
  position: absolute;
  top: 0;
  left: 0;
}

.SM-categoryLink a img:nth-child(1) {
  opacity: 0;
  transition: 0.6s opacity;
  z-index: 2;
}

.SM-categoryLink a img:nth-child(2) {
  transition: 0.2s opacity;
  z-index: 1;
}

.SM-categoryLink .SM-categoryLink_motif {
  width: 240px;
  width: 64vw;
  height: 240px;
  height: 64vw;
}

.SM-categoryLink .SM-categoryLink_item {
  width: 224px;
  width: 59.73333vw;
  height: 224px;
  height: 59.73333vw;
}

.SM-categoryLink .SM-categoryLink_neko {
  width: 214px;
  width: 57.06667vw;
  height: 214px;
  height: 57.06667vw;
}

@media screen and (min-width: 980px) {
  .SM-categoryMain .SM-main .SM-categoryLink {
    flex-direction: row;
    justify-content: center;
    width: 980px;
    margin: 0 auto;
    padding: 52px 0;
  }
  .SM-categoryMain .SM-main .SM-categoryLink a {
    margin: 20px;
  }
  .SM-categoryMain .SM-main .SM-categoryLink a:hover img:nth-child(1) {
    opacity: 1;
    transition: 0.2s opacity;
    z-index: 1;
  }
  .SM-categoryMain .SM-main .SM-categoryLink a:hover img:nth-child(2) {
    opacity: 0;
    transition: 0.6s opacity;
    z-index: 2;
  }
  .SM-categoryMain .SM-main .SM-categoryLink a:not(:first-child) {
    margin: 20px;
  }
  .SM-categoryMain .SM-main .SM-categoryLink a span {
    font-size: 16px;
  }
  .SM-categoryMain .SM-main .SM-categoryLink .SM-categoryLink_motif {
    width: 262px;
    height: 262px;
  }
  .SM-categoryMain .SM-main .SM-categoryLink .SM-categoryLink_item {
    width: 262px;
    height: 262px;
  }
  .SM-categoryMain .SM-main .SM-categoryLink .SM-categoryLink_neko {
    width: 262px;
    height: 262px;
  }
}

@keyframes nkg_kf_sp {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5vw);
  }
}

@keyframes nkg_kf_pc {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.SM-top .SM-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SM-top .SM-main .entry-header {
  display: none;
}

.SM-top .SM-main .SM-topMain_kv {
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 243px;
  height: 64.8vw;
}

.SM-top .SM-main .SM-topMain_kv > img {
  height: 460px;
  height: 122.66667vw;
  transform: translate(2.66667vw, 5.33333vw);
  max-width: none;
}

.SM-top .SM-main .SM-sec {
  flex-direction: column;
  width: 100%;
  padding: 36px 0 0;
  padding: 9.6vw 0 0;
}

.SM-top .SM-main .SM-sec > h2 {
  width: 138px;
  width: 36.8vw;
  line-height: 1;
  font-size: 0;
  margin: 0;
}

.SM-top .SM-main .SM-sec.SM-top_cate {
  z-index: 1;
}

.SM-top .SM-main .SM-sec.SM-top_new {
  padding: 64px 0 0;
  z-index: 1;
}

.SM-top .SM-main .SM-sec.SM-top_new .SM-product_tile {
  padding-top: 13px;
  padding-top: 3.46667vw;
}

.SM-top .SM-main .SM-sec.SM-sec_news {
  position: relative;
  margin-top: 64px;
  margin-top: 17.06667vw;
  background-color: #F4F1ED;
}

.SM-top .SM-main .SM-sec.SM-sec_news .SM-instafeed {
  border: none;
}

.SM-top .SM-main .SM-sec.SM-sec_news .post-inner {
  padding-top: 0;
}

.SM-top .SM-main .SM-sec.SM-sec_news .SM-l_sec_news {
  z-index: 1;
}

.SM-top .SM-main .SM-sec.SM-sec_news .SM-l_sec_news > article {
  padding-top: 32px;
  padding-top: 8.53333vw;
}

.SM-top .SM-main .SM-sec.SM-sec_news .entry-content > p {
  display: none;
}

.SM-top .SM-main .SM-sec.SM-sec_news .SM-sbi_footer {
  width: 100%;
  padding: 0 16px 48px;
}

.SM-top .SM-main .SM-sec.SM-sec_news .SM-sbi_footer > div {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  padding-bottom: 36px;
}

.SM-top .SM-main .SM-sec.SM-sec_news .SM-sbi_footer > div .SM-btn_link {
  margin: 0;
}

.SM-top .SM-main .SM-topMain_categoryList {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SM-top .SM-main .SM-topMain_categoryList a {
  display: block;
  position: relative;
  width: 240px;
  width: 64vw;
}

.SM-top .SM-main .SM-topMain_categoryList a p {
  position: absolute;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 16px;
  font-size: 14px;
  font-size: 3.73333vw;
}

.SM-top .SM-main .SM-topMain_categoryList a p span {
  background-color: #ffffff;
}

.SM-top .SM-main .SM-topMain_categoryList a img {
  position: absolute;
  top: 0;
  left: 0;
}

.SM-top .SM-main .SM-topMain_categoryList a img:nth-child(1) {
  opacity: 0;
  transition: 0.6s opacity;
  z-index: 2;
}

.SM-top .SM-main .SM-topMain_categoryList a img:nth-child(2) {
  transition: 0.2s opacity;
  z-index: 1;
}

.SM-top .SM-main .SM-topMain_categoryList a:not(:first-child) {
  padding-top: 20px;
  padding-top: 5.33333vw;
}

.SM-top .SM-main .SM-topMain_categoryList a:nth-child(1) {
  width: 240px;
  width: 64vw;
  margin-top: 25px;
  margin-top: 6.66667vw;
  height: 230px;
  height: 61.33333vw;
}

.SM-top .SM-main .SM-topMain_categoryList a:nth-child(2) {
  width: 223px;
  width: 59.46667vw;
  margin-top: 15px;
  margin-top: 4vw;
  height: 211px;
  height: 56.26667vw;
}

.SM-top .SM-main .SM-topMain_categoryList a:nth-child(3) {
  width: 213px;
  width: 56.8vw;
  margin-top: 25px;
  margin-top: 6.66667vw;
  height: 211px;
  height: 56.26667vw;
}

.SM-top .SM-main .SM-btn_link {
  margin-top: 32px;
  margin-top: 8.53333vw;
}

.SM-top .SM-main .SM-top_nkg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.SM-top .SM-main .SM-top_nkg_left {
  animation: 3s nkg_kf_sp infinite;
  position: fixed;
  bottom: 200px;
  bottom: 53.33333vw;
  left: -60px;
  left: -16vw;
  width: 152px;
  width: 40.53333vw;
  height: 109px;
  height: 29.06667vw;
  z-index: 0;
}

.SM-top .SM-main .SM-top_nkg_right {
  animation: 3.3s nkg_kf_sp infinite;
  position: fixed;
  bottom: 0;
  right: -75px;
  right: -20vw;
  width: 125px;
  width: 33.33333vw;
  height: 157px;
  height: 41.86667vw;
  z-index: 0;
}

@media screen and (min-width: 480px) {
  .SM-top .SM-main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .SM-top .SM-main .entry-header {
    display: none;
  }
  .SM-top .SM-main .SM-topMain_kv {
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    z-index: 1;
    height: 256px;
    height: 36.57143vw;
  }
  .SM-top .SM-main .SM-topMain_kv > img {
    height: 530px;
    height: 75.71429vw;
    transform: translate(2.66667vw, 2.66667vw);
    max-width: none;
  }
  .SM-top .SM-main .SM-sec {
    flex-direction: column;
    width: 100%;
    padding: 36px 0 0;
    padding: 9.6vw 0 0;
  }
  .SM-top .SM-main .SM-sec > h2 {
    width: 138px;
    width: 19.71429vw;
    line-height: 1;
    font-size: 0;
    margin: 0;
  }
  .SM-top .SM-main .SM-sec.SM-top_new {
    padding: 64px 0 0;
  }
  .SM-top .SM-main .SM-sec.SM-top_new .SM-product_tile {
    padding-top: 13px;
    padding-top: 3.46667vw;
  }
  .SM-top .SM-main .SM-sec.SM-sec_news {
    position: relative;
    margin-top: 64px;
    margin-top: 17.06667vw;
    background-color: #F4F1ED;
  }
  .SM-top .SM-main .SM-sec.SM-sec_news .post-inner {
    padding-top: 0;
  }
  .SM-top .SM-main .SM-sec.SM-sec_news .SM-l_sec_news > article {
    padding-top: 32px;
    padding-top: 8.53333vw;
  }
  .SM-top .SM-main .SM-sec.SM-sec_news .entry-content > p {
    display: none;
  }
  .SM-top .SM-main .SM-sec.SM-sec_news .SM-sbi_footer {
    width: 100%;
    padding: 0 16px 48px;
  }
  .SM-top .SM-main .SM-sec.SM-sec_news .SM-sbi_footer > div {
    display: flex;
    justify-content: center;
    background-color: #ffffff;
    padding-bottom: 36px;
  }
  .SM-top .SM-main .SM-sec.SM-sec_news .SM-sbi_footer > div .SM-btn_link {
    margin: 0;
  }
  .SM-top .SM-main .SM-topMain_categoryList {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 500px;
    width: 71.42857vw;
    justify-content: center;
    flex-wrap: wrap;
  }
  .SM-top .SM-main .SM-topMain_categoryList a {
    display: block;
    position: relative;
    width: 240px;
    width: 34.28571vw;
  }
  .SM-top .SM-main .SM-topMain_categoryList a p {
    position: absolute;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 16px;
    font-size: 14px;
    font-size: 2vw;
  }
  .SM-top .SM-main .SM-topMain_categoryList a:hover img:nth-child(1) {
    opacity: 1;
    transition: 0.2s opacity;
    z-index: 1;
  }
  .SM-top .SM-main .SM-topMain_categoryList a:hover img:nth-child(2) {
    opacity: 0;
    transition: 0.6s opacity;
    z-index: 2;
  }
  .SM-top .SM-main .SM-topMain_categoryList a img {
    position: absolute;
    top: 0;
    left: 0;
  }
  .SM-top .SM-main .SM-topMain_categoryList a img:nth-child(1) {
    opacity: 0;
    transition: 0.6s opacity;
    z-index: 2;
  }
  .SM-top .SM-main .SM-topMain_categoryList a img:nth-child(2) {
    transition: 0.2s opacity;
    z-index: 1;
  }
  .SM-top .SM-main .SM-topMain_categoryList a:not(:first-child) {
    padding-top: 20px;
    padding-top: 2.85714vw;
  }
  .SM-top .SM-main .SM-topMain_categoryList a:nth-child(1) {
    width: 240px;
    width: 34.28571vw;
    margin-top: 25px;
    margin-top: 3.57143vw;
    margin-right: 13.5px;
    margin-right: 1.92857vw;
    height: 230px;
    height: 32.85714vw;
  }
  .SM-top .SM-main .SM-topMain_categoryList a:nth-child(2) {
    width: 223px;
    width: 31.85714vw;
    margin-top: 25px;
    margin-top: 3.57143vw;
    margin-left: 13.5px;
    margin-left: 1.92857vw;
    height: 230px;
    height: 32.85714vw;
  }
  .SM-top .SM-main .SM-topMain_categoryList a:nth-child(3) {
    width: 213px;
    width: 30.42857vw;
    margin-top: 25px;
    margin-top: 3.57143vw;
    height: 211px;
    height: 30.14286vw;
  }
  .SM-top .SM-main .SM-btn_link {
    margin-top: 32px;
    margin-top: 8.53333vw;
  }
  .SM-top .SM-main .SM-top_nkg_left {
    position: fixed;
    bottom: 200px;
    bottom: 28.57143vw;
    left: -30px;
    left: -4.28571vw;
    width: 152px;
    width: 21.71429vw;
    height: 109px;
    height: 15.57143vw;
    z-index: 0;
  }
  .SM-top .SM-main .SM-top_nkg_right {
    position: fixed;
    right: -40px;
    right: -5.71429vw;
    width: 125px;
    width: 17.85714vw;
    height: 157px;
    height: 22.42857vw;
    z-index: 0;
  }
}

@media screen and (min-width: 980px) {
  .SM-top .SM-main {
    position: relative;
    overflow: hidden;
  }
  .SM-top .SM-main .SM-topMain_kv {
    height: 42vw;
    max-height: 650px;
  }
  .SM-top .SM-main .SM-topMain_kv > img {
    width: 100%;
    height: auto;
    transform: translate(0, 3vw);
  }
  .SM-top .SM-main .SM-btn_link {
    margin-top: 52px;
  }
  .SM-top .SM-main .SM-sec {
    padding: 80px 0 0;
  }
  .SM-top .SM-main .SM-sec > h2 {
    width: 184px;
  }
  .SM-top .SM-main .SM-sec.SM-top_new {
    padding: 90px 0 96px;
  }
  .SM-top .SM-main .SM-sec.SM-top_new .SM-product_tile {
    padding-top: 45px;
    z-index: 1;
  }
  .SM-top .SM-main .SM-sec.SM-sec_news {
    margin-top: 0;
    padding-top: 0;
  }
  .SM-top .SM-main .SM-sec.SM-sec_news .SM-l_sec_news {
    width: 980px;
  }
  .SM-top .SM-main .SM-sec.SM-sec_news .SM-sbi_footer {
    width: 980px;
    padding: 0 16px 120px;
  }
  .SM-top .SM-main .SM-sec.SM-sec_news .SM-l_sec_news > article {
    width: 980px;
    padding-top: 96px;
  }
  .SM-top .SM-main .SM-sec.SM-sec_news > h2 {
    position: absolute;
    top: 160px;
    z-index: 2;
  }
  .SM-top .SM-main .SM-topMain_categoryList {
    flex-direction: row;
    padding-top: 45px;
    z-index: 1;
    width: auto;
  }
  .SM-top .SM-main .SM-topMain_categoryList a {
    width: 326px;
    height: 310px;
  }
  .SM-top .SM-main .SM-topMain_categoryList a:nth-child(1) {
    width: 326px;
    height: 320px;
    padding-top: 0;
    margin-top: 0;
    margin-right: 0;
  }
  .SM-top .SM-main .SM-topMain_categoryList a:nth-child(2) {
    width: 326px;
    height: 320px;
    margin-top: 0;
    padding-top: 0;
    margin-left: 0;
  }
  .SM-top .SM-main .SM-topMain_categoryList a:nth-child(3) {
    width: 326px;
    height: 320px;
    margin-top: 0;
    padding-top: 0;
  }
  .SM-top .SM-main .SM-topMain_categoryList a p {
    font-size: 16px;
  }
  .SM-top .SM-main .SM-topMain_categoryList a:hover img:nth-child(1) {
    opacity: 1;
    transition: 0.2s opacity;
    z-index: 1;
  }
  .SM-top .SM-main .SM-topMain_categoryList a:hover img:nth-child(2) {
    opacity: 0;
    transition: 0.6s opacity;
    z-index: 2;
  }
  .SM-top .SM-main.SM-stt_nkg_on .SM-top_nkg_left {
    opacity: 1;
  }
  .SM-top .SM-main.SM-stt_nkg_on .SM-top_nkg_right {
    opacity: 1;
  }
  .SM-top .SM-main .SM-top_nkg_left {
    animation: 5.6s nkg_kf_pc infinite;
    transition: opacity 1s;
    position: fixed;
    top: 200px;
    left: -100px;
    width: 280px;
    height: 196px;
    z-index: 0;
    opacity: 0;
  }
  .SM-top .SM-main .SM-top_nkg_right {
    animation: 5.3s nkg_kf_pc infinite;
    transition: opacity 1s;
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 191px;
    height: 239px;
    z-index: 0;
    opacity: 0;
  }
}

.SM-sec_news .entry-content {
  padding: 0 16px;
}

#sb_instagram {
  background-color: #ffffff;
  padding-bottom: 0 !important;
  position: relative;
}

#sb_instagram .sbi_lightbox_carousel_icon {
  display: none !important;
}

#sb_instagram:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 25px solid #F4F1ED;
  border-right: 25px solid #F4F1ED;
  border-bottom: 25px solid #DED1C0;
  border-left: 25px solid #DED1C0;
}

#sb_instagram #sbi_images {
  padding: 16px !important;
}

#sb_instagram #sbi_images .sbi_item {
  padding: 16px 0 !important;
  transition: none !important;
  cursor: pointer;
}

#sb_instagram #sbi_images .sbi_item:nth-child(n+2) {
  border-top: 1px solid #F4F1ED;
}

@media screen and (min-width: 948px) {
  #sb_instagram #sbi_images {
    padding: 60px !important;
  }
  #sb_instagram #sbi_images .sbi_item {
    padding: 48px 0 !important;
  }
}

#sb_instagram .sbi_link {
  display: none;
}

#sb_instagram .sbi_photo_wrap {
  display: flex;
}

#sb_instagram .sbi_photo {
  border-radius: 100%;
  overflow: hidden;
  transition: none !important;
  width: 88px;
  width: 23.46667vw;
  height: 88px !important;
  height: 23.46667vw !important;
  padding-bottom: 0 !important;
}

@media screen and (min-width: 480px) {
  #sb_instagram .sbi_photo {
    width: 88px;
    width: 12.57143vw;
    height: 88px !important;
    height: 12.57143vw !important;
  }
}

@media screen and (min-width: 948px) {
  #sb_instagram .sbi_photo {
    width: 264px;
    height: 264px !important;
  }
}

#sb_instagram .sbi_photo > img {
  width: 100%;
  height: 100%;
}

#sb_instagram .sbi_info {
  font-family: 游ゴシック体, YuGothic, 游ゴシック, "Yu Gothic", メイリオ, sans-serif !important;
  text-align: left;
  padding-top: 16px;
  font-size: 14px;
}

#sb_instagram .sbi_info .sbi_caption_wrap {
  position: relative;
  line-height: 1.6;
}

#sb_instagram .sbi_info .sbi_caption_wrap:after {
  content: "続きを読む";
  font-size: 14px;
  position: absolute;
  bottom: 1px;
  color: #946C37;
  padding-left: 50px;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 30%);
  right: 6px;
  right: 1.6vw;
}

@media screen and (min-width: 480px) {
  #sb_instagram .sbi_info .sbi_caption_wrap:after {
    right: 12px;
    right: 1.71429vw;
  }
}

@media screen and (min-width: 948px) {
  #sb_instagram .sbi_info .sbi_caption_wrap:after {
    right: 8px;
  }
}

@media screen and (min-width: 948px) {
  #sb_instagram .sbi_info {
    padding-left: 300px;
    margin-top: -235px;
  }
}

#sb_instagram .SM-sbiInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  flex: 1;
  line-height: 1;
  font-family: 游ゴシック体, YuGothic, 游ゴシック, "Yu Gothic", メイリオ, sans-serif !important;
}

#sb_instagram .SM-sbiInfo .SM-sbiInfo_user {
  font-weight: 600;
  font-size: 16px;
}

#sb_instagram .SM-sbiInfo .SM-sbiInfo_date {
  font-size: 12px;
  color: #8E8E8E;
  padding-top: 10px;
}

@media screen and (min-width: 948px) {
  #sb_instagram .SM-sbiInfo {
    justify-content: flex-start;
    padding-left: 40px;
    flex-direction: row;
  }
  #sb_instagram .SM-sbiInfo .SM-sbiInfo_date {
    padding: 0 0 0 20px;
  }
}

@media screen and (min-width: 948px) {
  #sb_instagram {
    padding-top: 90px;
  }
}

#sb_instagram:not(.sbi_moderation_mode) .sbi_photo_wrap:hover .sbi_photo {
  transform: scale(1) !important;
}

.SM-about .SM-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SM-about .SM-main .entry-header {
  display: none;
}

.SM-about .SM-main .SM-aboutMain_kv {
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 176px;
  height: 46.93333vw;
}

@media screen and (min-width: 980px) {
  .SM-about .SM-main .SM-aboutMain_kv {
    height: 35vw;
    max-height: 650px;
  }
}

.SM-about .SM-main .SM-aboutMain_kv > img {
  height: 200px;
  height: 53.33333vw;
  transform: translate(2.66667vw, 1.33333vw);
  max-width: none;
}

@media screen and (min-width: 980px) {
  .SM-about .SM-main .SM-aboutMain_kv > img {
    transform: translate(0, 0);
    width: 100%;
    height: auto;
  }
}

.SM-about .SM-main .SM-sec {
  flex-direction: column;
  width: 100%;
  padding: 56px 16px 0;
  padding: 14.93333vw 4.26667vw 0;
}

@media screen and (min-width: 980px) {
  .SM-about .SM-main .SM-sec {
    padding: 80px 0;
  }
}

.SM-about .SM-main .SM-sec.SM-about_main > h2 {
  width: 190px;
  width: 50.66667vw;
  line-height: 1;
  font-size: 0;
  margin: 0;
}

@media screen and (min-width: 980px) {
  .SM-about .SM-main .SM-sec.SM-about_main > h2 {
    width: 253px;
  }
}

.SM-about .SM-main .SM-sec.SM-about_main .SM-about_txt {
  font-size: 14px;
  line-height: 2;
  font-family: 'Noto Sans JP';
  padding: 33px 0 0;
  padding: 8.8vw 0 0;
}

.SM-about .SM-main .SM-sec.SM-about_main .SM-about_txt p {
  padding: 20px 0;
}

@media screen and (min-width: 980px) {
  .SM-about .SM-main .SM-sec.SM-about_main .SM-about_txt {
    width: 652px;
    text-align: center;
    padding: 53px 0 0;
  }
}

@media screen and (min-width: 980px) {
  .SM-about .SM-main .SM-sec.SM-about_main {
    padding-bottom: 0;
  }
}

.SM-about .SM-main .SM-sec.SM-about_sns > h2 {
  width: 93px;
  width: 24.8vw;
  line-height: 1;
  font-size: 0;
  margin: 0;
}

@media screen and (min-width: 980px) {
  .SM-about .SM-main .SM-sec.SM-about_sns > h2 {
    width: 123px;
  }
}

.SM-about .SM-main .SM-sec.SM-about_sns ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 47px;
}

.SM-about .SM-main .SM-sec.SM-about_sns ul li > div {
  width: 70px;
  width: 18.66667vw;
  height: 70px;
  height: 18.66667vw;
}

@media screen and (min-width: 980px) {
  .SM-about .SM-main .SM-sec.SM-about_sns ul li > div {
    width: 70px;
    height: 70px;
  }
}

.SM-about .SM-main .SM-sec.SM-about_sns ul li > h3 {
  display: flex;
  font-size: 20px;
  letter-spacing: 0.3em;
  padding-top: 20px;
}

.SM-about .SM-main .SM-sec.SM-about_sns ul li > h3 svg {
  margin-right: 10px;
}

.SM-about .SM-main .SM-sec.SM-about_sns ul li > p {
  font-size: 14px;
  color: #aeaeae;
  letter-spacing: 0.1em;
  padding-top: 8px;
}

.SM-about .SM-main .SM-sec.SM-about_sns ul li > a {
  margin-top: 20px;
}

@media screen and (min-width: 980px) {
  .SM-about .SM-main .SM-sec.SM-about_sns ul {
    display: flex;
    padding-top: 72px;
  }
  .SM-about .SM-main .SM-sec.SM-about_sns ul li {
    justify-content: space-between;
    padding: 0 36px 0;
  }
}

@media screen and (min-width: 980px) {
  .SM-about .SM-main .SM-sec.SM-about_sns {
    padding-bottom: 0;
  }
}

.SM-about .SM-main .SM-sec.SM-about_instagram {
  padding: 80px 0 0;
  padding: 21.33333vw 0 0;
}

.SM-about .SM-main .SM-sec.SM-about_instagram ul {
  display: flex;
  flex-wrap: wrap;
}

.SM-about .SM-main .SM-sec.SM-about_instagram ul li {
  width: 33.3333%;
}

.SM-about .SM-main .SM-sec.SM-about_instagram ul li:last-child {
  display: none;
}

.SM-about .SM-main .SM-sec.SM-about_instagram ul li a {
  display: block;
  transition: opacity 0.7s;
}

.SM-about .SM-main .SM-sec.SM-about_instagram ul li a:hover {
  opacity: 0.7;
}

@media screen and (min-width: 980px) {
  .SM-about .SM-main .SM-sec.SM-about_instagram ul li {
    width: 20%;
  }
  .SM-about .SM-main .SM-sec.SM-about_instagram ul li:last-child {
    display: list-item;
  }
}

@media screen and (min-width: 980px) {
  .SM-about .SM-main .SM-sec.SM-about_instagram {
    width: 100%;
    max-width: 1440px;
    padding-top: 120px;
  }
}

.SM-about .SM-main .SM-topMain_categoryList {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SM-about .SM-main .SM-topMain_categoryList a {
  display: block;
  position: relative;
  width: 240px;
  width: 64vw;
}

.SM-about .SM-main .SM-topMain_categoryList a span {
  position: absolute;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 16px;
  font-size: 14px;
  font-size: 3.73333vw;
  font-weight: bold;
}

.SM-about .SM-main .SM-topMain_categoryList a:hover img:nth-child(1) {
  opacity: 1;
  transition: 0.2s opacity;
  z-index: 1;
}

.SM-about .SM-main .SM-topMain_categoryList a:hover img:nth-child(2) {
  opacity: 0;
  transition: 0.6s opacity;
  z-index: 2;
}

.SM-about .SM-main .SM-topMain_categoryList a img {
  position: absolute;
  top: 0;
  left: 0;
}

.SM-about .SM-main .SM-topMain_categoryList a img:nth-child(1) {
  opacity: 0;
  transition: 0.6s opacity;
  z-index: 2;
}

.SM-about .SM-main .SM-topMain_categoryList a img:nth-child(2) {
  transition: 0.2s opacity;
  z-index: 1;
}

.SM-about .SM-main .SM-topMain_categoryList a:not(:first-child) {
  padding-top: 20px;
  padding-top: 5.33333vw;
}

.SM-about .SM-main .SM-topMain_categoryList a:nth-child(1) {
  width: 240px;
  width: 64vw;
  margin-top: 25px;
  margin-top: 6.66667vw;
  height: 230px;
  height: 61.33333vw;
}

.SM-about .SM-main .SM-topMain_categoryList a:nth-child(2) {
  width: 223px;
  width: 59.46667vw;
  height: 211px;
  height: 56.26667vw;
}

.SM-about .SM-main .SM-topMain_categoryList a:nth-child(3) {
  width: 213px;
  width: 56.8vw;
  height: 211px;
  height: 56.26667vw;
}

@media screen and (min-width: 980px) {
  .SM-about .SM-main .SM-topMain_categoryList {
    flex-direction: row;
    padding-top: 45px;
  }
  .SM-about .SM-main .SM-topMain_categoryList a {
    width: 326px;
    height: 310px;
  }
  .SM-about .SM-main .SM-topMain_categoryList a:nth-child(1) {
    width: 326px;
    height: 310px;
    padding-top: 0;
  }
  .SM-about .SM-main .SM-topMain_categoryList a:nth-child(2) {
    width: 326px;
    height: 310px;
    padding-top: 0;
  }
  .SM-about .SM-main .SM-topMain_categoryList a:nth-child(3) {
    width: 326px;
    height: 310px;
    padding-top: 0;
  }
  .SM-about .SM-main .SM-topMain_categoryList a span {
    font-size: 16px;
  }
}

.SM-about .SM-main .SM-btn_link {
  margin-top: 32px;
  margin-top: 8.53333vw;
}

.SM-sec_news .entry-content {
  padding: 0 16px;
}

#sb_instagram {
  background-color: #ffffff;
  padding-bottom: 0 !important;
  position: relative;
}

#sb_instagram .sbi_lightbox_carousel_icon {
  display: none !important;
}

#sb_instagram:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 25px solid #F4F1ED;
  border-right: 25px solid #F4F1ED;
  border-bottom: 25px solid #DED1C0;
  border-left: 25px solid #DED1C0;
}

#sb_instagram #sbi_images {
  padding: 16px !important;
}

#sb_instagram #sbi_images .sbi_item {
  padding: 16px 0 !important;
  transition: none !important;
  cursor: pointer;
}

#sb_instagram #sbi_images .sbi_item:nth-child(n+2) {
  border-top: 1px solid #F4F1ED;
}

@media screen and (min-width: 948px) {
  #sb_instagram #sbi_images {
    padding: 60px !important;
  }
  #sb_instagram #sbi_images .sbi_item {
    padding: 48px 0 !important;
  }
}

#sb_instagram .sbi_link {
  display: none;
}

#sb_instagram .sbi_photo_wrap {
  display: flex;
}

#sb_instagram .sbi_photo {
  border-radius: 100%;
  overflow: hidden;
  transition: none !important;
  width: 88px;
  width: 23.46667vw;
  height: 88px !important;
  height: 23.46667vw !important;
  padding-bottom: 0 !important;
}

@media screen and (min-width: 480px) {
  #sb_instagram .sbi_photo {
    width: 88px;
    width: 12.57143vw;
    height: 88px !important;
    height: 12.57143vw !important;
  }
}

@media screen and (min-width: 948px) {
  #sb_instagram .sbi_photo {
    width: 264px;
    height: 264px !important;
  }
}

#sb_instagram .sbi_photo > img {
  width: 100%;
  height: 100%;
}

#sb_instagram .sbi_info {
  font-family: 游ゴシック体, YuGothic, 游ゴシック, "Yu Gothic", メイリオ, sans-serif !important;
  text-align: left;
  padding-top: 16px;
  font-size: 14px;
}

#sb_instagram .sbi_info .sbi_caption_wrap {
  position: relative;
  line-height: 1.6;
}

#sb_instagram .sbi_info .sbi_caption_wrap:after {
  content: "続きを読む";
  font-size: 14px;
  position: absolute;
  bottom: 1px;
  color: #946C37;
  padding-left: 50px;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 30%);
  right: 6px;
  right: 1.6vw;
}

@media screen and (min-width: 480px) {
  #sb_instagram .sbi_info .sbi_caption_wrap:after {
    right: 12px;
    right: 1.71429vw;
  }
}

@media screen and (min-width: 948px) {
  #sb_instagram .sbi_info .sbi_caption_wrap:after {
    right: 8px;
  }
}

@media screen and (min-width: 948px) {
  #sb_instagram .sbi_info {
    padding-left: 300px;
    margin-top: -235px;
  }
}

#sb_instagram .SM-sbiInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  flex: 1;
  line-height: 1;
  font-family: 游ゴシック体, YuGothic, 游ゴシック, "Yu Gothic", メイリオ, sans-serif !important;
}

#sb_instagram .SM-sbiInfo .SM-sbiInfo_user {
  font-weight: 600;
  font-size: 16px;
}

#sb_instagram .SM-sbiInfo .SM-sbiInfo_date {
  font-size: 12px;
  color: #8E8E8E;
  padding-top: 10px;
}

@media screen and (min-width: 948px) {
  #sb_instagram .SM-sbiInfo {
    justify-content: flex-start;
    padding-left: 40px;
    flex-direction: row;
  }
  #sb_instagram .SM-sbiInfo .SM-sbiInfo_date {
    padding: 0 0 0 20px;
  }
}

@media screen and (min-width: 948px) {
  #sb_instagram {
    padding-top: 90px;
  }
}

#sb_instagram:not(.sbi_moderation_mode) .sbi_photo_wrap:hover .sbi_photo {
  transform: scale(1) !important;
}

.SM-contact .SM-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SM-contact .SM-main .entry-header {
  display: none;
}

.SM-contact .SM-main .SM-contactMain_kv {
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 176px;
  height: 46.93333vw;
}

@media screen and (min-width: 980px) {
  .SM-contact .SM-main .SM-contactMain_kv {
    height: 512px;
  }
}

.SM-contact .SM-main .SM-contactMain_kv > img {
  height: 200px;
  height: 53.33333vw;
  transform: translate(2.66667vw, 1.33333vw);
  max-width: none;
}

@media screen and (min-width: 980px) {
  .SM-contact .SM-main .SM-contactMain_kv > img {
    height: 512px;
    transform: translate(0, 0);
  }
}

.SM-contact .SM-main .SM-sec {
  flex-direction: column;
  width: 100%;
  padding: 56px 16px 0;
  padding: 14.93333vw 4.26667vw 0;
}

@media screen and (min-width: 980px) {
  .SM-contact .SM-main .SM-sec {
    padding: 80px 0;
  }
}

.SM-contact .SM-main .SM-sec.SM-contact_main > article {
  width: 100%;
}

.SM-contact .SM-main .SM-sec.SM-contact_main > h2 {
  width: 138px;
  width: 36.8vw;
  line-height: 1;
  font-size: 0;
  margin: 0;
}

@media screen and (min-width: 980px) {
  .SM-contact .SM-main .SM-sec.SM-contact_main > h2 {
    width: 183px;
  }
}

.SM-contact .SM-main .SM-sec.SM-contact_main form .wpcf7-response-output {
  border: none;
  text-align: center;
  font-size: 15px;
}

.SM-contact .SM-main .SM-sec.SM-contact_main form > p {
  padding-top: 20px;
}

.SM-contact .SM-main .SM-sec.SM-contact_main form > p:nth-child(6) {
  position: relative;
}

.SM-contact .SM-main .SM-sec.SM-contact_main form > p:nth-child(6) .wpcf7-spinner {
  position: absolute;
  bottom: -120px;
  right: 0;
  top: 0;
  left: 0;
  margin: auto;
}

.SM-contact .SM-main .SM-sec.SM-contact_main form input[type="submit"] {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 40px;
  background: #FDF0A3;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 700;
  color: #000000;
  text-decoration: none;
  letter-spacing: 0.1em;
  line-height: 1;
  transition: background-color 0.4s;
  margin: 20px auto 0;
}

.SM-contact .SM-main .SM-sec.SM-contact_main form input[type="submit"]:hover {
  background-color: #e8cd3f;
}

.SM-contact .SM-main .SM-sec.SM-contact_main form label {
  font-size: 14px;
  line-height: 2;
}

.SM-contact .SM-main .SM-sec.SM-contact_main form label > span i {
  color: #BA2223;
  font-style: normal;
  padding-left: 5px;
}

.SM-contact .SM-main .SM-sec.SM-contact_main form label input {
  width: 100%;
  height: 48px;
  border: 1px solid #CDCDCD;
  box-sizing: border-box;
  border-radius: 2px;
  margin-top: 5px;
  padding: 10px;
}

.SM-contact .SM-main .SM-sec.SM-contact_main form label input::placeholder {
  color: #cdcdcd;
}

.SM-contact .SM-main .SM-sec.SM-contact_main form label textarea {
  border: 1px solid #CDCDCD;
  height: 248px;
  margin-top: 5px;
  padding: 10px;
  resize: vertical;
}

.SM-contact .SM-main .SM-sec.SM-contact_main form label textarea::placeholder {
  color: #cdcdcd;
}

@media screen and (min-width: 980px) {
  .SM-contact .SM-main .SM-sec.SM-contact_main form label {
    display: flex;
  }
  .SM-contact .SM-main .SM-sec.SM-contact_main form label input {
    width: 415px;
  }
  .SM-contact .SM-main .SM-sec.SM-contact_main form label > span:nth-child(1) {
    width: 20%;
  }
  .SM-contact .SM-main .SM-sec.SM-contact_main form label > span:nth-child(2) {
    width: 80%;
  }
}

@media screen and (min-width: 980px) {
  .SM-contact .SM-main .SM-sec.SM-contact_main form {
    max-width: 980px;
    padding: 0 20px;
    margin: 0 auto;
  }
}

.SM-contact .SM-main .SM-sec.SM-contact_main .post-inner {
  padding-top: 20px;
}

.SM-contact .SM-main .SM-sec.SM-contact_main > p {
  padding-top: 40px;
  padding-top: 10.66667vw;
  font-size: 14px;
  line-height: 2;
}

.SM-contact .SM-main .SM-sec.SM-contact_main > p span {
  color: #BA2223;
}

@media screen and (min-width: 980px) {
  .SM-contact .SM-main .SM-sec.SM-contact_main > p {
    padding-top: 64px;
  }
}

.SM-contact .SM-main .SM-sec.SM-contact_main .SM-contact_txt {
  font-size: 14px;
  line-height: 2;
  padding: 33px 0 0;
  padding: 8.8vw 0 0;
}

.SM-contact .SM-main .SM-sec.SM-contact_main .SM-contact_txt p {
  padding: 20px 0;
}

@media screen and (min-width: 980px) {
  .SM-contact .SM-main .SM-sec.SM-contact_main .SM-contact_txt {
    width: 652px;
    text-align: center;
    padding: 53px 0 0;
  }
}

@media screen and (min-width: 980px) {
  .SM-contact .SM-main .SM-sec.SM-contact_main {
    padding-bottom: 50px;
  }
}

.SM-contact .SM-main .SM-sec.SM-contact_sns > h2 {
  width: 93px;
  width: 24.8vw;
  line-height: 1;
  font-size: 0;
  margin: 0;
}

@media screen and (min-width: 980px) {
  .SM-contact .SM-main .SM-sec.SM-contact_sns > h2 {
    width: 123px;
  }
}

.SM-contact .SM-main .SM-sec.SM-contact_sns ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 47px;
}

.SM-contact .SM-main .SM-sec.SM-contact_sns ul li > div {
  width: 70px;
  width: 18.66667vw;
  height: 70px;
  height: 18.66667vw;
}

@media screen and (min-width: 980px) {
  .SM-contact .SM-main .SM-sec.SM-contact_sns ul li > div {
    width: 70px;
    height: 70px;
  }
}

.SM-contact .SM-main .SM-sec.SM-contact_sns ul li > h3 {
  display: flex;
  font-size: 20px;
  letter-spacing: 0.3em;
  padding-top: 20px;
}

.SM-contact .SM-main .SM-sec.SM-contact_sns ul li > h3 svg {
  margin-right: 10px;
}

.SM-contact .SM-main .SM-sec.SM-contact_sns ul li > p {
  font-size: 14px;
  color: #aeaeae;
  letter-spacing: 0.1em;
  padding-top: 8px;
}

.SM-contact .SM-main .SM-sec.SM-contact_sns ul li > a {
  margin-top: 20px;
}

@media screen and (min-width: 980px) {
  .SM-contact .SM-main .SM-sec.SM-contact_sns ul {
    display: flex;
    padding-top: 72px;
  }
  .SM-contact .SM-main .SM-sec.SM-contact_sns ul li {
    justify-content: space-between;
    padding: 0 36px 0;
  }
}

@media screen and (min-width: 980px) {
  .SM-contact .SM-main .SM-sec.SM-contact_sns {
    padding-bottom: 0;
  }
}

.SM-contact .SM-main .SM-sec.SM-contact_instagram {
  padding: 80px 0 0;
  padding: 21.33333vw 0 0;
}

.SM-contact .SM-main .SM-sec.SM-contact_instagram ul {
  display: flex;
  flex-wrap: wrap;
}

.SM-contact .SM-main .SM-sec.SM-contact_instagram ul li {
  width: 33.3333%;
}

.SM-contact .SM-main .SM-sec.SM-contact_instagram ul li:last-child {
  display: none;
}

.SM-contact .SM-main .SM-sec.SM-contact_instagram ul li a {
  display: block;
  transition: opacity 0.7s;
}

.SM-contact .SM-main .SM-sec.SM-contact_instagram ul li a:hover {
  opacity: 0.7;
}

@media screen and (min-width: 980px) {
  .SM-contact .SM-main .SM-sec.SM-contact_instagram ul li {
    width: 20%;
  }
  .SM-contact .SM-main .SM-sec.SM-contact_instagram ul li:last-child {
    display: list-item;
  }
}

@media screen and (min-width: 980px) {
  .SM-contact .SM-main .SM-sec.SM-contact_instagram {
    width: 100%;
    max-width: 1440px;
    padding-top: 120px;
  }
}

.SM-contact .SM-main .SM-topMain_categoryList {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SM-contact .SM-main .SM-topMain_categoryList a {
  display: block;
  position: relative;
  width: 240px;
  width: 64vw;
}

.SM-contact .SM-main .SM-topMain_categoryList a span {
  position: absolute;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 16px;
  font-size: 14px;
  font-size: 3.73333vw;
  font-weight: bold;
}

.SM-contact .SM-main .SM-topMain_categoryList a:hover img:nth-child(1) {
  opacity: 1;
  transition: 0.2s opacity;
  z-index: 1;
}

.SM-contact .SM-main .SM-topMain_categoryList a:hover img:nth-child(2) {
  opacity: 0;
  transition: 0.6s opacity;
  z-index: 2;
}

.SM-contact .SM-main .SM-topMain_categoryList a img {
  position: absolute;
  top: 0;
  left: 0;
}

.SM-contact .SM-main .SM-topMain_categoryList a img:nth-child(1) {
  opacity: 0;
  transition: 0.6s opacity;
  z-index: 2;
}

.SM-contact .SM-main .SM-topMain_categoryList a img:nth-child(2) {
  transition: 0.2s opacity;
  z-index: 1;
}

.SM-contact .SM-main .SM-topMain_categoryList a:not(:first-child) {
  padding-top: 20px;
  padding-top: 5.33333vw;
}

.SM-contact .SM-main .SM-topMain_categoryList a:nth-child(1) {
  width: 240px;
  width: 64vw;
  margin-top: 25px;
  margin-top: 6.66667vw;
  height: 230px;
  height: 61.33333vw;
}

.SM-contact .SM-main .SM-topMain_categoryList a:nth-child(2) {
  width: 223px;
  width: 59.46667vw;
  height: 211px;
  height: 56.26667vw;
}

.SM-contact .SM-main .SM-topMain_categoryList a:nth-child(3) {
  width: 213px;
  width: 56.8vw;
  height: 211px;
  height: 56.26667vw;
}

@media screen and (min-width: 980px) {
  .SM-contact .SM-main .SM-topMain_categoryList {
    flex-direction: row;
    padding-top: 45px;
  }
  .SM-contact .SM-main .SM-topMain_categoryList a {
    width: 326px;
    height: 310px;
  }
  .SM-contact .SM-main .SM-topMain_categoryList a:nth-child(1) {
    width: 326px;
    height: 310px;
    padding-top: 0;
  }
  .SM-contact .SM-main .SM-topMain_categoryList a:nth-child(2) {
    width: 326px;
    height: 310px;
    padding-top: 0;
  }
  .SM-contact .SM-main .SM-topMain_categoryList a:nth-child(3) {
    width: 326px;
    height: 310px;
    padding-top: 0;
  }
  .SM-contact .SM-main .SM-topMain_categoryList a span {
    font-size: 16px;
  }
}

.SM-contact .SM-main .SM-btn_link {
  margin-top: 32px;
  margin-top: 8.53333vw;
}

.SM-sec_news .entry-content {
  padding: 0 16px;
}

#sb_instagram {
  background-color: #ffffff;
  padding-bottom: 0 !important;
  position: relative;
}

#sb_instagram .sbi_lightbox_carousel_icon {
  display: none !important;
}

#sb_instagram:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 25px solid #F4F1ED;
  border-right: 25px solid #F4F1ED;
  border-bottom: 25px solid #DED1C0;
  border-left: 25px solid #DED1C0;
}

#sb_instagram #sbi_images {
  padding: 16px !important;
}

#sb_instagram #sbi_images .sbi_item {
  padding: 16px 0 !important;
  transition: none !important;
  cursor: pointer;
}

#sb_instagram #sbi_images .sbi_item:nth-child(n+2) {
  border-top: 1px solid #F4F1ED;
}

@media screen and (min-width: 948px) {
  #sb_instagram #sbi_images {
    padding: 60px !important;
  }
  #sb_instagram #sbi_images .sbi_item {
    padding: 48px 0 !important;
  }
}

#sb_instagram .sbi_link {
  display: none;
}

#sb_instagram .sbi_photo_wrap {
  display: flex;
}

#sb_instagram .sbi_photo {
  border-radius: 100%;
  overflow: hidden;
  transition: none !important;
  width: 88px;
  width: 23.46667vw;
  height: 88px !important;
  height: 23.46667vw !important;
  padding-bottom: 0 !important;
}

@media screen and (min-width: 480px) {
  #sb_instagram .sbi_photo {
    width: 88px;
    width: 12.57143vw;
    height: 88px !important;
    height: 12.57143vw !important;
  }
}

@media screen and (min-width: 948px) {
  #sb_instagram .sbi_photo {
    width: 264px;
    height: 264px !important;
  }
}

#sb_instagram .sbi_photo > img {
  width: 100%;
  height: 100%;
}

#sb_instagram .sbi_info {
  font-family: 游ゴシック体, YuGothic, 游ゴシック, "Yu Gothic", メイリオ, sans-serif !important;
  text-align: left;
  padding-top: 16px;
  font-size: 14px;
}

#sb_instagram .sbi_info .sbi_caption_wrap {
  position: relative;
  line-height: 1.6;
}

#sb_instagram .sbi_info .sbi_caption_wrap:after {
  content: "続きを読む";
  font-size: 14px;
  position: absolute;
  bottom: 1px;
  color: #946C37;
  padding-left: 50px;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 30%);
  right: 6px;
  right: 1.6vw;
}

@media screen and (min-width: 480px) {
  #sb_instagram .sbi_info .sbi_caption_wrap:after {
    right: 12px;
    right: 1.71429vw;
  }
}

@media screen and (min-width: 948px) {
  #sb_instagram .sbi_info .sbi_caption_wrap:after {
    right: 8px;
  }
}

@media screen and (min-width: 948px) {
  #sb_instagram .sbi_info {
    padding-left: 300px;
    margin-top: -235px;
  }
}

#sb_instagram .SM-sbiInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  flex: 1;
  line-height: 1;
  font-family: 游ゴシック体, YuGothic, 游ゴシック, "Yu Gothic", メイリオ, sans-serif !important;
}

#sb_instagram .SM-sbiInfo .SM-sbiInfo_user {
  font-weight: 600;
  font-size: 16px;
}

#sb_instagram .SM-sbiInfo .SM-sbiInfo_date {
  font-size: 12px;
  color: #8E8E8E;
  padding-top: 10px;
}

@media screen and (min-width: 948px) {
  #sb_instagram .SM-sbiInfo {
    justify-content: flex-start;
    padding-left: 40px;
    flex-direction: row;
  }
  #sb_instagram .SM-sbiInfo .SM-sbiInfo_date {
    padding: 0 0 0 20px;
  }
}

@media screen and (min-width: 948px) {
  #sb_instagram {
    padding-top: 90px;
  }
}

#sb_instagram:not(.sbi_moderation_mode) .sbi_photo_wrap:hover .sbi_photo {
  transform: scale(1) !important;
}

.SM-handmade .SM-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SM-handmade .SM-main .entry-header {
  display: none;
}

.SM-handmade .SM-main .SM-handmadeMain_kv {
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: 176px;
  height: 46.93333vw;
}

@media screen and (min-width: 980px) {
  .SM-handmade .SM-main .SM-handmadeMain_kv {
    height: 35vw;
    max-height: 650px;
  }
}

.SM-handmade .SM-main .SM-handmadeMain_kv > img {
  height: 260px;
  height: 69.33333vw;
  max-width: none;
}

@media screen and (min-width: 980px) {
  .SM-handmade .SM-main .SM-handmadeMain_kv > img {
    width: 100%;
    height: auto;
  }
}

.SM-handmade .SM-main .SM-sec {
  flex-direction: column;
  width: 100%;
  padding: 56px 16px 0;
  padding: 14.93333vw 4.26667vw 0;
}

@media screen and (min-width: 980px) {
  .SM-handmade .SM-main .SM-sec {
    padding: 80px 0;
  }
}

.SM-handmade .SM-main .SM-sec.SM-handmade_main > h2 {
  width: 168px;
  width: 44.8vw;
  line-height: 1;
  font-size: 0;
  margin: 0;
}

@media screen and (min-width: 980px) {
  .SM-handmade .SM-main .SM-sec.SM-handmade_main > h2 {
    width: 225px;
  }
}

.SM-handmade .SM-main .SM-sec.SM-handmade_main .SM-handmade_txt {
  font-size: 14px;
  line-height: 2;
  font-family: 'Noto Sans JP';
  padding: 33px 0 0;
  padding: 8.8vw 0 0;
}

.SM-handmade .SM-main .SM-sec.SM-handmade_main .SM-handmade_txt p {
  padding: 20px 0;
}

@media screen and (min-width: 980px) {
  .SM-handmade .SM-main .SM-sec.SM-handmade_main .SM-handmade_txt {
    width: 652px;
    text-align: center;
    padding: 53px 0 0;
  }
}

@media screen and (min-width: 980px) {
  .SM-handmade .SM-main .SM-sec.SM-handmade_main {
    padding-bottom: 100px;
  }
}

.SM-handmade .SM-main .SM-sec.SM-handmade_sns > h2 {
  width: 93px;
  width: 24.8vw;
  line-height: 1;
  font-size: 0;
  margin: 0;
}

@media screen and (min-width: 980px) {
  .SM-handmade .SM-main .SM-sec.SM-handmade_sns > h2 {
    width: 123px;
  }
}

.SM-handmade .SM-main .SM-sec.SM-handmade_sns ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 47px;
}

.SM-handmade .SM-main .SM-sec.SM-handmade_sns ul li > div {
  width: 70px;
  width: 18.66667vw;
  height: 70px;
  height: 18.66667vw;
}

@media screen and (min-width: 980px) {
  .SM-handmade .SM-main .SM-sec.SM-handmade_sns ul li > div {
    width: 70px;
    height: 70px;
  }
}

.SM-handmade .SM-main .SM-sec.SM-handmade_sns ul li > h3 {
  display: flex;
  font-size: 20px;
  letter-spacing: 0.3em;
  padding-top: 20px;
}

.SM-handmade .SM-main .SM-sec.SM-handmade_sns ul li > h3 svg {
  margin-right: 10px;
}

.SM-handmade .SM-main .SM-sec.SM-handmade_sns ul li > p {
  font-size: 14px;
  color: #aeaeae;
  letter-spacing: 0.1em;
  padding-top: 8px;
}

.SM-handmade .SM-main .SM-sec.SM-handmade_sns ul li > a {
  margin-top: 20px;
}

@media screen and (min-width: 980px) {
  .SM-handmade .SM-main .SM-sec.SM-handmade_sns ul {
    display: flex;
    padding-top: 72px;
  }
  .SM-handmade .SM-main .SM-sec.SM-handmade_sns ul li {
    justify-content: space-between;
    padding: 0 36px 0;
  }
}

@media screen and (min-width: 980px) {
  .SM-handmade .SM-main .SM-sec.SM-handmade_sns {
    padding-bottom: 0;
  }
}

.SM-handmade .SM-main .SM-sec.SM-sec_news {
  padding: 56px 0 48px;
  padding: 14.93333vw 0 12.8vw;
  position: relative;
  margin-top: 64px;
  margin-top: 17.06667vw;
  background-color: #F4F1ED;
}

.SM-handmade .SM-main .SM-sec.SM-sec_news .post-inner {
  padding-top: 0;
}

.SM-handmade .SM-main .SM-sec.SM-sec_news h2 {
  width: 138px;
  width: 36.8vw;
}

.SM-handmade .SM-main .SM-sec.SM-sec_news > article {
  width: 100%;
  padding-top: 32px;
  padding-top: 8.53333vw;
}

.SM-handmade .SM-main .SM-sec.SM-sec_news .entry-content > p {
  display: none;
}

.SM-handmade .SM-main .SM-sec.SM-sec_news .SM-sbi_footer {
  width: 100%;
  padding: 0 16px 48px;
}

.SM-handmade .SM-main .SM-sec.SM-sec_news .SM-sbi_footer > div {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  padding-bottom: 36px;
}

.SM-handmade .SM-main .SM-sec.SM-sec_news .SM-sbi_footer > div .SM-btn_link {
  margin: 0;
}

@media screen and (min-width: 980px) {
  .SM-handmade .SM-main .SM-sec.SM-sec_news .SM-sbi_footer {
    width: 980px;
  }
}

@media screen and (min-width: 980px) {
  .SM-handmade .SM-main .SM-sec.SM-sec_news {
    margin-top: 0;
    padding-top: 0;
  }
  .SM-handmade .SM-main .SM-sec.SM-sec_news > article {
    width: 980px;
    padding-top: 96px;
  }
  .SM-handmade .SM-main .SM-sec.SM-sec_news > h2 {
    position: absolute;
    width: 184px;
    top: 160px;
    z-index: 1;
  }
}

.SM-handmade .SM-main .SM-sec.SM-handmade_instagram {
  padding: 80px 0 0;
  padding: 21.33333vw 0 0;
}

.SM-handmade .SM-main .SM-sec.SM-handmade_instagram ul {
  display: flex;
  flex-wrap: wrap;
}

.SM-handmade .SM-main .SM-sec.SM-handmade_instagram ul li {
  width: 33.3333%;
}

.SM-handmade .SM-main .SM-sec.SM-handmade_instagram ul li:last-child {
  display: none;
}

.SM-handmade .SM-main .SM-sec.SM-handmade_instagram ul li a {
  display: block;
  transition: opacity 0.7s;
}

.SM-handmade .SM-main .SM-sec.SM-handmade_instagram ul li a:hover {
  opacity: 0.7;
}

@media screen and (min-width: 980px) {
  .SM-handmade .SM-main .SM-sec.SM-handmade_instagram ul li {
    width: 20%;
  }
  .SM-handmade .SM-main .SM-sec.SM-handmade_instagram ul li:last-child {
    display: list-item;
  }
}

@media screen and (min-width: 980px) {
  .SM-handmade .SM-main .SM-sec.SM-handmade_instagram {
    width: 100%;
    max-width: 1440px;
    padding-top: 120px;
  }
}

.SM-handmade .SM-main .SM-topMain_categoryList {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SM-handmade .SM-main .SM-topMain_categoryList a {
  display: block;
  position: relative;
  width: 240px;
  width: 64vw;
}

.SM-handmade .SM-main .SM-topMain_categoryList a span {
  position: absolute;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 16px;
  font-size: 14px;
  font-size: 3.73333vw;
  font-weight: bold;
}

.SM-handmade .SM-main .SM-topMain_categoryList a:hover img:nth-child(1) {
  opacity: 1;
  transition: 0.2s opacity;
  z-index: 1;
}

.SM-handmade .SM-main .SM-topMain_categoryList a:hover img:nth-child(2) {
  opacity: 0;
  transition: 0.6s opacity;
  z-index: 2;
}

.SM-handmade .SM-main .SM-topMain_categoryList a img {
  position: absolute;
  top: 0;
  left: 0;
}

.SM-handmade .SM-main .SM-topMain_categoryList a img:nth-child(1) {
  opacity: 0;
  transition: 0.6s opacity;
  z-index: 2;
}

.SM-handmade .SM-main .SM-topMain_categoryList a img:nth-child(2) {
  transition: 0.2s opacity;
  z-index: 1;
}

.SM-handmade .SM-main .SM-topMain_categoryList a:not(:first-child) {
  padding-top: 20px;
  padding-top: 5.33333vw;
}

.SM-handmade .SM-main .SM-topMain_categoryList a:nth-child(1) {
  width: 240px;
  width: 64vw;
  margin-top: 25px;
  margin-top: 6.66667vw;
  height: 230px;
  height: 61.33333vw;
}

.SM-handmade .SM-main .SM-topMain_categoryList a:nth-child(2) {
  width: 223px;
  width: 59.46667vw;
  height: 211px;
  height: 56.26667vw;
}

.SM-handmade .SM-main .SM-topMain_categoryList a:nth-child(3) {
  width: 213px;
  width: 56.8vw;
  height: 211px;
  height: 56.26667vw;
}

@media screen and (min-width: 980px) {
  .SM-handmade .SM-main .SM-topMain_categoryList {
    flex-direction: row;
    padding-top: 45px;
  }
  .SM-handmade .SM-main .SM-topMain_categoryList a {
    width: 326px;
    height: 310px;
  }
  .SM-handmade .SM-main .SM-topMain_categoryList a:nth-child(1) {
    width: 326px;
    height: 310px;
    padding-top: 0;
  }
  .SM-handmade .SM-main .SM-topMain_categoryList a:nth-child(2) {
    width: 326px;
    height: 310px;
    padding-top: 0;
  }
  .SM-handmade .SM-main .SM-topMain_categoryList a:nth-child(3) {
    width: 326px;
    height: 310px;
    padding-top: 0;
  }
  .SM-handmade .SM-main .SM-topMain_categoryList a span {
    font-size: 16px;
  }
}

.SM-handmade .SM-main .SM-btn_link {
  margin-top: 32px;
  margin-top: 8.53333vw;
}

.SM-sec_news .entry-content {
  padding: 0 16px;
}

#sb_instagram {
  background-color: #ffffff;
  padding-bottom: 0 !important;
  position: relative;
}

#sb_instagram .sbi_lightbox_carousel_icon {
  display: none !important;
}

#sb_instagram:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 25px solid #F4F1ED;
  border-right: 25px solid #F4F1ED;
  border-bottom: 25px solid #DED1C0;
  border-left: 25px solid #DED1C0;
}

#sb_instagram #sbi_images {
  padding: 16px !important;
}

#sb_instagram #sbi_images .sbi_item {
  padding: 16px 0 !important;
  transition: none !important;
  cursor: pointer;
}

#sb_instagram #sbi_images .sbi_item:nth-child(n+2) {
  border-top: 1px solid #F4F1ED;
}

@media screen and (min-width: 948px) {
  #sb_instagram #sbi_images {
    padding: 60px !important;
  }
  #sb_instagram #sbi_images .sbi_item {
    padding: 48px 0 !important;
  }
}

#sb_instagram .sbi_link {
  display: none;
}

#sb_instagram .sbi_photo_wrap {
  display: flex;
}

#sb_instagram .sbi_photo {
  border-radius: 100%;
  overflow: hidden;
  transition: none !important;
  width: 88px;
  width: 23.46667vw;
  height: 88px !important;
  height: 23.46667vw !important;
  padding-bottom: 0 !important;
}

@media screen and (min-width: 480px) {
  #sb_instagram .sbi_photo {
    width: 88px;
    width: 12.57143vw;
    height: 88px !important;
    height: 12.57143vw !important;
  }
}

@media screen and (min-width: 948px) {
  #sb_instagram .sbi_photo {
    width: 264px;
    height: 264px !important;
  }
}

#sb_instagram .sbi_photo > img {
  width: 100%;
  height: 100%;
}

#sb_instagram .sbi_info {
  font-family: 游ゴシック体, YuGothic, 游ゴシック, "Yu Gothic", メイリオ, sans-serif !important;
  text-align: left;
  padding-top: 16px;
  font-size: 14px;
}

#sb_instagram .sbi_info .sbi_caption_wrap {
  position: relative;
  line-height: 1.6;
}

#sb_instagram .sbi_info .sbi_caption_wrap:after {
  content: "続きを読む";
  font-size: 14px;
  position: absolute;
  bottom: 1px;
  color: #946C37;
  padding-left: 50px;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 30%);
  right: 6px;
  right: 1.6vw;
}

@media screen and (min-width: 480px) {
  #sb_instagram .sbi_info .sbi_caption_wrap:after {
    right: 12px;
    right: 1.71429vw;
  }
}

@media screen and (min-width: 948px) {
  #sb_instagram .sbi_info .sbi_caption_wrap:after {
    right: 8px;
  }
}

@media screen and (min-width: 948px) {
  #sb_instagram .sbi_info {
    padding-left: 300px;
    margin-top: -235px;
  }
}

#sb_instagram .SM-sbiInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  flex: 1;
  line-height: 1;
  font-family: 游ゴシック体, YuGothic, 游ゴシック, "Yu Gothic", メイリオ, sans-serif !important;
}

#sb_instagram .SM-sbiInfo .SM-sbiInfo_user {
  font-weight: 600;
  font-size: 16px;
}

#sb_instagram .SM-sbiInfo .SM-sbiInfo_date {
  font-size: 12px;
  color: #8E8E8E;
  padding-top: 10px;
}

@media screen and (min-width: 948px) {
  #sb_instagram .SM-sbiInfo {
    justify-content: flex-start;
    padding-left: 40px;
    flex-direction: row;
  }
  #sb_instagram .SM-sbiInfo .SM-sbiInfo_date {
    padding: 0 0 0 20px;
  }
}

@media screen and (min-width: 948px) {
  #sb_instagram {
    padding-top: 90px;
  }
}

#sb_instagram:not(.sbi_moderation_mode) .sbi_photo_wrap:hover .sbi_photo {
  transform: scale(1) !important;
}
