.SM-contact {
  .SM-main {
    .entry-header {
      display: none;
    }
    .SM-contactMain_kv {
      display: flex;
      justify-content: center;
      overflow: hidden;
      //height: 65vw;
      width: 100%;
      //height: 243px;
      @include vw(176, height);
      @include mq() {
        height: 512px;
      }
      > img {
        @include vw(200, height);
        transform: translate(getVW(10), getVW(5));
        max-width: none;
        @include mq() {
          height: 512px;
          transform: translate(0, 0);
        }
      }
    }
    //0.26
    //.SM-topMain_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      //padding-top: 40px;

      //padding-top: 50px;
      .SM-sec {
        //display: flex;
        flex-direction: column;
        //align-items: center;
        width: 100%;
        @include vw((56, 16, 0), padding);
        @include mq() {
          padding: 80px 0;
        }

        &.SM-contact_main {
          > article {
            width: 100%;
          }
          > h2 {
            //width: 138px;
            //width: 35.88vw;
            @include vw(138, width);
            line-height: 1;
            font-size: 0;
            margin: 0;
            @include mq() {
              width: 183px;
            }
          }
          form {
            .wpcf7-response-output {
              border: none;
              text-align: center;
              font-size: 15px;
            }
            > p {
              padding-top: 20px;
              &:nth-child(6) {
                position: relative;
                .wpcf7-spinner {
                  position: absolute;
                  bottom: -120px;
                  right: 0;
                  top: 0;
                  left: 0;
                  margin: auto;
                }
              }
            }
            input[type="submit"] {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 240px;
              height: 40px;
              background: #FDF0A3;
              //background: #EEDC7D;
              border-radius: 2px;
              font-size: 14px;
              font-weight: 700;
              color: #000000;
              //color: #FFFFFF;
              text-decoration: none;
              letter-spacing: 0.1em;
              line-height: 1;
              transition: background-color 0.4s;
              margin: 20px auto 0;
              &:hover {
                background-color: #e8cd3f;
              }
            }
            label {
              font-size: 14px;
              line-height: 2;
              > span i {
                color: #BA2223;
                font-style: normal;
                padding-left: 5px;
              }
              input {
                width: 100%;
                height: 48px;
                border: 1px solid #CDCDCD;
                box-sizing: border-box;
                border-radius: 2px;
                margin-top: 5px;
                padding: 10px;
                &::placeholder {
                  color: #cdcdcd;
                }
              }
              textarea {
                border: 1px solid #CDCDCD;
                height: 248px;
                margin-top: 5px;
                padding: 10px;
                resize: vertical;
                &::placeholder {
                  color: #cdcdcd;
                }
              }
              @include mq() {
                display: flex;
                input {
                  width: 415px;
                }
                > span {
                  &:nth-child(1) {
                    width: 20%;
                  }
                  &:nth-child(2) {
                    width: 80%;
                  }
                }
              }
            }
            @include mq() {
              max-width: 980px;
              padding: 0 20px;
              margin: 0 auto;
              //max-width: 980px;

            }
          }
          .post-inner {
            padding-top: 20px;
          }
          > p {
            @include vw(40, padding-top);
            font-size: 14px;
            line-height: 2;
            span {
              color: #BA2223;
            }
            @include mq() {
              padding-top: 64px;
              //text-align: center;
            }
          }
          .SM-contact_txt {
            font-size: 14px;
            line-height: 2;
            @include vw((33, 0, 0), padding);
            p {
              padding: 20px 0;
            }
            @include mq() {
              width: 652px;
              text-align: center;
              padding: 53px 0 0;
            }
          }
          @include mq() {
            padding-bottom: 50px;
          }
        }
        &.SM-contact_sns {
          > h2 {
            @include vw(93, width);
            line-height: 1;
            font-size: 0;
            margin: 0;
            @include mq() {
              width: 123px;
            }
          }
          ul {
            li {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding-top: 47px;
              > div {
                @include vw(70, width);
                @include vw(70, height);
                @include mq() {
                  width: 70px;
                  height: 70px;
                }
              }
              > h3 {
                display: flex;
                font-size: 20px;
                letter-spacing: 0.3em;
                padding-top: 20px;
                svg {
                  margin-right: 10px;
                }
              }
              > p {
                font-size: 14px;
                color: #aeaeae;
                letter-spacing: 0.1em;
                padding-top: 8px;
              }
              > a {
                margin-top: 20px;
              }
              //&:nth-child(1) {
              //  > h3 {
              //    &:before {
              //      content: "";
              //
              //    }
              //  }
              //}
            }
            @include mq() {
              display: flex;
              padding-top: 72px;
              li {
                justify-content: space-between;
                padding: 0 36px 0;
              }
            }
          }
          @include mq() {
            padding-bottom: 0;
          }
        }
        &.SM-contact_instagram {
          @include vw((80, 0, 0), padding);
          ul {
            display: flex;
            flex-wrap: wrap;
            li {
              width: 33.3333%;
              &:last-child {
                display: none;
              }
              a {
                display: block;
                transition: opacity 0.7s;
                &:hover {
                  opacity: 0.7;
                }
              }
              @include mq() {
                width: 20%;
                &:last-child {
                  display: list-item;
                }
              }
            }
          }
          @include mq() {
            width: 100%;
            max-width: 1440px;
            padding-top: 120px;
          }
        }
      }
      .SM-topMain_categoryList {
        display: flex;
        flex-direction: column;
        align-items: center;
        a {
          display: block;
          position: relative;

          @include vw(240, width);

          span {
            position: absolute;
            bottom: 0;
            margin: auto;
            left: 0;
            right: 0;
            text-align: center;
            font-size: 16px;
            @include vw(14);
            //font-family: Noto Sans CJK JP;
            font-weight: bold;
          }


          &:hover {
            img {
              &:nth-child(1) {
                opacity: 1;
                transition: 0.2s opacity;
                z-index: 1;

              }
              &:nth-child(2) {
                opacity: 0;
                //transition-delay: 0s;
                transition: 0.6s opacity;
                z-index: 2;

              }
            }
          }

          img {
            position: absolute;
            top: 0;
            left: 0;
            &:nth-child(1) {
              opacity: 0;
              transition: 0.6s opacity;
              z-index: 2;
            }
            &:nth-child(2) {
              transition: 0.2s opacity;
              z-index: 1;

            }

          }

          &:not(:first-child) {
            @include vw(20, padding-top);
          }

          &:nth-child(1) {
            //width: 240px;
            @include vw(240, width);
            @include vw(25, margin-top);
            @include vw(230, height);



          }
          &:nth-child(2) {
            //width: 240px;
            @include vw(223, width);

            @include vw(211, height);

          }
          &:nth-child(3) {
            //width: 240px;
            @include vw(213, width);

            @include vw(211, height);

          }
        }
        @include mq() {
          flex-direction: row;
          padding-top: 45px;
          a {
            width: 326px;
            height: 310px;

            &:nth-child(1) {
              width: 326px;
              //height: 279px;
              height: 310px;
              padding-top: 0;

            }

            &:nth-child(2) {
              width: 326px;
              height: 310px;

              padding-top: 0;

            }

            &:nth-child(3) {
              width: 326px;
              height: 310px;

              padding-top: 0;

            }

            span {
              font-size: 16px;
            }
          }
        }
      }
    //}
    .SM-btn_link {
      @include vw(32, margin-top);
    }
  }
}

@import "insta_feed";