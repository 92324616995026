.SM-sec_news {
  .entry-content {
    padding: 0 16px;
  }
}
#sb_instagram {
  background-color: #ffffff;
  //padding-top: 31px;
  padding-bottom: 0 !important;
  position: relative;
  .sbi_lightbox_carousel_icon {
    display: none !important;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-top: 25px solid #F4F1ED;
    border-right: 25px solid #F4F1ED;
    border-bottom: 25px solid #DED1C0;
    border-left: 25px solid #DED1C0;
  }
  #sbi_images {
    padding: 16px !important;
    //margin: 0;
    .sbi_item {
      padding: 16px 0 !important;
      transition: none !important;
      cursor: pointer;

      &:nth-child(n+2) {
        border-top: 1px solid #F4F1ED;
      }
      a {
        //pointer-events: none;
      }
    }
    @include mq('iframe') {
      padding: 60px !important;
      .sbi_item {
        padding: 48px 0 !important;
      }
    }
  }
  .sbi_link {
    display: none;
  }
  .sbi_photo_wrap {
    display: flex;


  }
  .sbi_photo {
    border-radius: 100%;
    overflow: hidden;
    transition: none !important;
    @include vw(88, width);
    @include vw((88, !important), height);
    padding-bottom: 0 !important;
    @include mq('tab') {
      @include vwtab(88, width);
      @include vwtab((88, !important), height);
    }
    @include mq('iframe') {
      width: 264px;
      height: 264px !important;
    }
    > img {
      width: 100%;
      height: 100%;
    }
  }
  .sbi_info {
    font-family: 游ゴシック体, YuGothic, 游ゴシック, "Yu Gothic", メイリオ, sans-serif !important;
    text-align: left;
    padding-top: 16px;
    font-size: 14px;
    .sbi_caption_wrap {
      position: relative;
      line-height: 1.6;
      &:after {
        content: "続きを読む";
        font-size: 14px;
        position: absolute;
        bottom: 1px;
        color: #946C37;
        //background-color: #ffffff;
        padding-left: 50px;
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 30%);
        @include vw(6, right);
        @include mq('tab') {
          @include vwtab(12, right);
        }
        @include mq('iframe') {
          right: 8px;
        }
      }
    }
    @include mq('iframe') {
      padding-left: 300px;
      margin-top: -235px;
    }
  }
  .SM-sbiInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
    flex: 1;
    line-height: 1;
    font-family: 游ゴシック体, YuGothic, 游ゴシック, "Yu Gothic", メイリオ, sans-serif !important;

    .SM-sbiInfo_user {
      font-weight: 600;
      font-size: 16px;
    }
    .SM-sbiInfo_date {
      font-size: 12px;
      color: #8E8E8E;
      padding-top: 10px;
    }
    @include mq('iframe') {
      justify-content: flex-start;
      padding-left: 40px;
      flex-direction: row;
      .SM-sbiInfo_date {
        padding: 0 0 0 20px;
      }

    }
  }

  @include mq('iframe') {
    padding-top: 90px;
  }
}

#sb_instagram:not(.sbi_moderation_mode) .sbi_photo_wrap:hover .sbi_photo {
  transform: scale(1) !important;
}
