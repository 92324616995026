@mixin clearfix {
    &::after {
        content: "";
        display: block;
        clear: both;
    }
}

@function getVW($size, $viewport:375) {
    @if type-of($size) != number {
        @return $size
    }
    @if $size == 0 {
        @return 0;
    }
    $rate: 100 / $viewport;
    @return $rate * $size * 1vw;
}

@mixin vw($size, $prop:font-size, $mqr:0, $bp:'sp') {
    $px: '';
    $vw: '';
    $mqpx: '';
    @for $i from 1 through length($size) {
        $value: nth($size, $i);
        $unit: '';
        @if type-of($value) == number {
            @if $value != 0 {
                $unit: 'px'
            }
        }
        $px: $px + nth($size, $i) + $unit;
        $vw: $vw + getVW(nth($size, $i));
        @if type-of($value) == number {
            $mqpx: $mqpx + nth($size, $i) * $mqr + $unit;
        } @else {
            $mqpx: $mqpx + nth($size, $i);
        }
        @if $i < length($size) {
            $px: $px + ' ';
            $vw: $vw + ' ';
            $mqpx: $mqpx + ' ';
        }
    };
    #{$prop}: #{$px};
    #{$prop}: #{$vw};
    @if $mqr > 0 {
        @include mq($bp) {
            #{$prop}: #{$mqpx};
        }
    }
}

@mixin vwtab($size, $prop:font-size, $mqr:1, $bp:'sp') {
    $px: '';
    $vw: '';
    $mqpx: '';
    @for $i from 1 through length($size) {
        $value: nth($size, $i);
        $unit: '';
        @if type-of($value) == number {
            @if $value != 0 {
                $unit: 'px'
            }
        }
        $px: $px + nth($size, $i) + $unit;
        $vw: $vw + getVW(nth($size, $i), 700);
        @if type-of($value) == number {
            $mqpx: $mqpx + nth($size, $i) * $mqr + $unit;
        } @else {
            $mqpx: $mqpx + nth($size, $i);
        }
        @if $i < length($size) {
            $px: $px + ' ';
            $vw: $vw + ' ';
            $mqpx: $mqpx + ' ';
        }
    };
    #{$prop}: #{$px};
    #{$prop}: #{$vw};
    //@if $mqr > 0 {
    //    @include mq($bp) {
    //        #{$prop}: #{$mqpx};
    //    }
    //}
    //@include mq('pc') {
    //    #{$prop}: #{$mqpx};
    //}

}
@mixin vwpc($size, $prop:font-size, $mqr:1, $bp:'sp') {
    $px: '';
    $vw: '';
    $mqpx: '';
    @for $i from 1 through length($size) {
        $value: nth($size, $i);
        $unit: '';
        @if type-of($value) == number {
            @if $value != 0 {
                $unit: 'px'
            }
        }
        $px: $px + nth($size, $i) + $unit;
        $vw: $vw + getVW(nth($size, $i), 1600);
        @if type-of($value) == number {
            $mqpx: $mqpx + nth($size, $i) * $mqr + $unit;
        } @else {
            $mqpx: $mqpx + nth($size, $i);
        }
        @if $i < length($size) {
            $px: $px + ' ';
            $vw: $vw + ' ';
            $mqpx: $mqpx + ' ';
        }
    };
    #{$prop}: #{$px};
    #{$prop}: #{$vw};
    //@if $mqr > 0 {
    //    @include mq($bp) {
    //        #{$prop}: #{$mqpx};
    //    }
    //}
    @include mq('pc2') {
        #{$prop}: #{$mqpx};
    }

}


@mixin numFont() {
    font-family: Helvetica Neue, Helvetica, Arial, Roboto, sans-serif;
}

@mixin placeholderColor($color) {
    &:placeholder-shown {
        color: $color;
    }
    &::-webkit-input-placeholder {
        color:$color;
    }
    &:-moz-placeholder {
        color:$color;
        opacity: 1;
    }
    &::-moz-placeholder {
        color:$color;
        opacity: 1;
    }
    &:-ms-input-placeholder {
        color:$color;
    }
}
