@keyframes nkg_kf_sp {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5vw);
  }
}

@keyframes nkg_kf_pc {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.SM-top {
  .SM-main {
    .entry-header {
      display: none;
    }
    .SM-topMain_kv {
      display: flex;
      justify-content: center;
      overflow: hidden;
      width: 100%;
      @include vw(243, height);
      > img {
        @include vw(460, height);
        transform: translate(getVW(10), getVW(20));
        max-width: none;
      }
    }
      display: flex;
      flex-direction: column;
      align-items: center;
      .SM-sec {
        flex-direction: column;
        width: 100%;
        @include vw((36, 0, 0), padding);
        > h2 {
        //.SM-l_sec_news > h2 {
          @include vw(138, width);
          line-height: 1;
          font-size: 0;
          margin: 0;
        }
        &.SM-top_cate {
          z-index: 1;
        }
        &.SM-top_new {
          padding: 64px 0 0;
          z-index: 1;
          .SM-product_tile {
            @include vw(13, padding-top);
            //background-color: #ffffffd6;
          }
        }
        &.SM-sec_news {

          .SM-instafeed {
            border: none;
            //height: 800px;
          }

          .post-inner {
            padding-top: 0;
          }
          .SM-l_sec_news {
            z-index: 1;
          }
          .SM-l_sec_news > article {
            @include vw(32, padding-top);
          }
          .entry-content {
            > p {
              display: none;
            }
          }
          position: relative;
          @include vw(64, margin-top);
          background-color: #F4F1ED;
          .SM-sbi_footer {
            width: 100%;
            padding: 0 16px 48px;
            > div {
              display: flex;
              justify-content: center;
              background-color: #ffffff;
              padding-bottom: 36px;
              .SM-btn_link {
                margin: 0;
              }
            }
          }
        }
      }
      .SM-topMain_categoryList {
        display: flex;
        flex-direction: column;
        align-items: center;
        a {
          display: block;
          position: relative;
          @include vw(240, width);
          p {
            position: absolute;
            bottom: 0;
            margin: auto;
            left: 0;
            right: 0;
            text-align: center;
            font-size: 16px;
            @include vw(14);
            //font-family: Noto Sans CJK JP;
            //font-weight: bold;
            span {
              background-color: #ffffff;
            }
          }
          //&:hover {
          //  img {
          //    &:nth-child(1) {
          //      opacity: 1;
          //      transition: 0.2s opacity;
          //      z-index: 1;
          //    }
          //    &:nth-child(2) {
          //      opacity: 0;
          //      transition: 0.6s opacity;
          //      z-index: 2;
          //    }
          //  }
          //}
          img {
            position: absolute;
            top: 0;
            left: 0;
            &:nth-child(1) {
              opacity: 0;
              transition: 0.6s opacity;
              z-index: 2;
            }
            &:nth-child(2) {
              transition: 0.2s opacity;
              z-index: 1;
            }
          }
          &:not(:first-child) {
            @include vw(20, padding-top);
          }
          &:nth-child(1) {
            @include vw(240, width);
            @include vw(25, margin-top);
            @include vw(230, height);
          }
          &:nth-child(2) {
            @include vw(223, width);
            @include vw(15, margin-top);
            @include vw(211, height);
          }
          &:nth-child(3) {
            @include vw(213, width);
            @include vw(25, margin-top);
            @include vw(211, height);
          }
        }
      }
    .SM-btn_link {
      @include vw(32, margin-top);
    }

    .SM-top_nkg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .SM-top_nkg_left {
      animation: 3s nkg_kf_sp infinite;
      position: fixed;
      @include vw(200, bottom);
      @include vw(-60, left);
      @include vw(152, width);
      @include vw(109, height);
      z-index: 0;
      //opacity: 0.3;
    }
    .SM-top_nkg_right {
      animation: 3.3s nkg_kf_sp infinite;
      position: fixed;
      //@include vw(574, top);
      bottom: 0;
      @include vw(-75, right);
      @include vw(125, width);
      @include vw(157, height);
      z-index: 0;
      //opacity: 0.3;
    }
  }


}
@include mq('tab') {
  .SM-top {
    .SM-main {
      .entry-header {
        display: none;
      }
      .SM-topMain_kv {
        display: flex;
        justify-content: center;
        overflow: hidden;
        width: 100%;
        z-index: 1;
        @include vwtab(256, height);
        > img {
          @include vwtab(530, height);
          transform: translate(getVW(10), getVW(10));
          max-width: none;
        }
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      .SM-sec {
        flex-direction: column;
        width: 100%;
        @include vw((36, 0, 0), padding);
        > h2 {
        //.SM-l_sec_news > h2 {
          @include vwtab(138, width);
          line-height: 1;
          font-size: 0;
          margin: 0;
        }
        &.SM-top_new {
          padding: 64px 0 0;
          .SM-product_tile {
            @include vw(13, padding-top);
          }
        }
        &.SM-sec_news {
          .post-inner {
            padding-top: 0;
          }
          .SM-l_sec_news > article {
            @include vw(32, padding-top);
          }
          .entry-content {
            > p {
              display: none;
            }
          }
          position: relative;
          @include vw(64, margin-top);
          background-color: #F4F1ED;
          .SM-sbi_footer {
            width: 100%;
            padding: 0 16px 48px;
            > div {
              display: flex;
              justify-content: center;
              background-color: #ffffff;
              padding-bottom: 36px;
              .SM-btn_link {
                margin: 0;
              }
            }
          }
        }
      }
      .SM-topMain_categoryList {
        display: flex;
        flex-direction: row;
        align-items: center;
        @include vwtab(500, width);
        justify-content: center;
        flex-wrap: wrap;
        a {
          display: block;
          position: relative;
          @include vwtab(240, width);
          p {
            position: absolute;
            bottom: 0;
            margin: auto;
            left: 0;
            right: 0;
            text-align: center;
            font-size: 16px;
            @include vwtab(14);
            //font-family: Noto Sans CJK JP;
            //font-weight: bold;
          }
          &:hover {
            img {
              &:nth-child(1) {
                opacity: 1;
                transition: 0.2s opacity;
                z-index: 1;
              }
              &:nth-child(2) {
                opacity: 0;
                transition: 0.6s opacity;
                z-index: 2;
              }
            }
          }
          img {
            position: absolute;
            top: 0;
            left: 0;
            &:nth-child(1) {
              opacity: 0;
              transition: 0.6s opacity;
              z-index: 2;
            }
            &:nth-child(2) {
              transition: 0.2s opacity;
              z-index: 1;
            }
          }
          &:not(:first-child) {
            @include vwtab(20, padding-top);
          }
          &:nth-child(1) {
            @include vwtab(240, width);
            @include vwtab(25, margin-top);
            @include vwtab(13.5, margin-right);
            @include vwtab(230, height);
          }
          &:nth-child(2) {
            @include vwtab(223, width);
            @include vwtab(25, margin-top);
            @include vwtab(13.5, margin-left);
            @include vwtab(230, height);
          }
          &:nth-child(3) {
            @include vwtab(213, width);
            @include vwtab(25, margin-top);
            @include vwtab(211, height);
          }
        }
      }
      .SM-btn_link {
        @include vw(32, margin-top);
      }
      .SM-top_nkg_left {
        position: fixed;
        @include vwtab(200, bottom);
        @include vwtab(-30, left);
        @include vwtab(152, width);
        @include vwtab(109, height);
        z-index: 0;
      }
      .SM-top_nkg_right {
        position: fixed;
        //@include vwtab(790, top);
        @include vwtab(-40, right);
        @include vwtab(125, width);
        @include vwtab(157, height);
        z-index: 0;
      }
    }

  }
}
@include mq() {
  .SM-top {
    .SM-main {
      position: relative;
      overflow: hidden;
      .SM-topMain_kv {
        //height: 512px;
        height: 42vw;
        max-height: 650px;
        > img {
          //height: 960px;
          //transform: translate(0, 25px);
          width: 100%;
          height: auto;
          transform: translate(0, 3vw);
        }
      }
      .SM-btn_link {
        margin-top: 52px;
      }
      .SM-sec {
        padding: 80px 0 0;
        > h2 {
        //.SM-l_sec_news > h2 {
          width: 184px;
        }
        &.SM-top_new {
          padding: 90px 0 96px;
          .SM-product_tile {
            padding-top: 45px;
            z-index: 1;
          }
        }
        &.SM-sec_news {
          .SM-l_sec_news {
            width: 980px;
          }
          .SM-sbi_footer {
            width: 980px;
            padding: 0 16px 120px;
          }
          margin-top: 0;
          padding-top: 0;
          .SM-l_sec_news > article {
            width: 980px;
            padding-top: 96px;
          }
          > h2 {
          //.SM-l_sec_news > h2 {
            position: absolute;
            top: 160px;
            z-index: 2;
          }
        }
      }
      .SM-topMain_categoryList {
        flex-direction: row;
        padding-top: 45px;
        z-index: 1;
        width: auto;
        a {
          width: 326px;
          height: 310px;
          &:nth-child(1) {
            width: 326px;
            height: 320px;
            padding-top: 0;
            margin-top: 0;
            margin-right: 0;
          }
          &:nth-child(2) {
            width: 326px;
            height: 320px;
            margin-top: 0;
            padding-top: 0;
            margin-left: 0;
          }
          &:nth-child(3) {
            width: 326px;
            height: 320px;
            margin-top: 0;
            padding-top: 0;
          }
          p {
            font-size: 16px;
          }
          &:hover {
            img {
              &:nth-child(1) {
                opacity: 1;
                transition: 0.2s opacity;
                z-index: 1;
              }
              &:nth-child(2) {
                opacity: 0;
                transition: 0.6s opacity;
                z-index: 2;
              }
            }
          }
        }
      }



      &.SM-stt_nkg_on {
        .SM-top_nkg_left {
          //left: -100px;
          opacity: 1;
        }
        .SM-top_nkg_right {
          //right: 10px;
          opacity: 1;
        }
      }
      .SM-top_nkg_left {
        animation: 5.6s nkg_kf_pc infinite;

        transition: opacity 1s;
        position: fixed;
        top: 200px;
        left: -100px;
        width: 280px;
        height: 196px;
        z-index: 0;
        opacity: 0;
      }
      .SM-top_nkg_right {
        animation: 5.3s nkg_kf_pc infinite;

        transition: opacity 1s;
        position: fixed;
        //top: 550px;
        //top: auto;
        bottom: 10px;
        right: 10px;
        width: 191px;
        height: 239px;
        z-index: 0;
        opacity: 0;
      }
    }


  }
}

@import "insta_feed";