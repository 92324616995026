.SM-btn_link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
  height: 40px;
  background: #FDF0A3;
  //background: #EEDC7D;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 700;
  color: #000000;
  //color: #FFFFFF;
  text-decoration: none;
  letter-spacing: 0.1em;
  line-height: 1;
  transition: background-color 0.4s;
  &:hover {
    background-color: #e8cd3f;
  }
  @include mq() {
    width: 220px;
  }
}

.SM-pagination {
  display: flex;
  max-width: 100%;
  font-size: 16px;
  font-weight: 400;
  font-family: 游ゴシック体, YuGothic, 游ゴシック, "Yu Gothic", メイリオ, sans-serif;
  @include vw(30, padding-top);
  > a, span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    margin: 0 10px;
  }
  .SM-pagination_move {
    background-image: url("/wp-content/themes/suimin-v1/img/common/pagination_arrow.svg");
    background-repeat: no-repeat;
    background-size: 10.5px 21px;
    background-position: center;
  }
  .SM-pagination_prev {
    transform: rotate(180deg);
  }
  .SM-pagination_current {
    background-color: #DED1C0;
    color: #FFFFFF;
  }
  span.placeholder {
    display: none;
  }
  @include mq() {
    padding-top: 80px;
  }
}