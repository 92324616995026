.SM-product_tile {
  display: flex;
  flex-wrap: wrap;
  padding: 0 9px;
  width: 100%;
  li {
    position: relative;
    width: 50%;
    margin-top: 15px;
    a {
      display: block;
      margin: 7px;
      div {
        &.SM-products_img {
          position: relative;
          &:before {
            content: "";
            display: block;
            padding-top: 100%;
          }
          > div {
            display: flex;
            @include vw(10, padding);
            position: absolute;
            top: 0;
            left: 0;
            margin: auto;
            bottom: 0;
            right: 0;
            background-color: #F8F6F4;
          }
          img {
            object-fit: contain;
            transition: 0.4s;
          }
        }
      }
      .SM-products_info {
        padding-top: 10px;
        display: inline-block;
        max-width: 100%;
        > div {
          font-size: 13px;
          letter-spacing: 2px;
          line-height: 18px;
          margin: 2px 0;
          background-color: #ffffff;
          width: fit-content;
        }
        .SM-products_title {
            overflow: hidden;
            display: inline-flex;
            //display: inline-block;
            width: 100%;
            p {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        //.SM-products_wrap {
        //    display: inline-flex;
        //    flex-direction: column;
        //    width: fit-content;
        //    font-size: 12px;
        //    color: #8B8B8B;
        //    letter-spacing: 1px;
        //    div {
        //      //display: inline;
        //      background-color: #ffffff;
          .SM-products_shopName {
                color: #8E8E8E;
                &:before {
                  display: inline-block;
                  content: "";
                  background-image: url("/wp-content/themes/suimin-v1/img/common/icon_external_link.png");
                  background-size: 12px 12px;
                  background-repeat: no-repeat;
                  width: 12px;
                  height: 12px;
                  margin-right: 3px;
                  vertical-align: -1px;
                }
              }
              //.SM-products_price {
              //  color: #000000;
              //}
            //}
          //}
        .SM-products_price {
            font-size: 12px;
          font-family: "Noto Sans JP";
        }
      }
      &:hover {
        .SM-products_img {
          img {
            opacity: 0.7;
          }
        }

      }
    }
    .PM-products_mm {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      //top: 15px;
      //right: 15px;
      @include vw(15, top);
      @include vw(15, right);
      //font-size: 10px;
      @include vw(10);
      color: #B69972;
      //width: 55px;
      @include vw((2, 4), padding);
      //@include vw(55, width);
      //height: 14px;
      //@include vw(14, height);
      //border-radius: 2px;
      @include vw(2, border-radius);
      background-color: #FFFFFF;
      text-align: center;
      //letter-spacing: 2px;
      @include vw(2, letter-spacing);
    }
  }
}
@include mq('tab') {
  .SM-product_tile {
    display: flex;
    flex-wrap: wrap;
    padding: 0 9px;
    width: 100%;
    li {
      position: relative;
      width: 33.3333%;
      margin-top: 15px;
      a {
        display: block;
        margin: 7px;
        div {
          &.SM-products_img {
            position: relative;
            &:before {
              content: "";
              display: block;
              padding-top: 100%;
            }
            > div {
              display: flex;
              @include vw(10, padding);
              position: absolute;
              top: 0;
              left: 0;
              margin: auto;
              bottom: 0;
              right: 0;
              background-color: #F8F6F4;
            }
            img {
              object-fit: contain;
              transition: 0.4s;
            }
          }
        }
        //.SM-products_info {
        //  padding-top: 10px;
        //  > div {
        //    font-size: 13px;
        //    letter-spacing: 2px;
        //    line-height: 18px;
        //    padding: 2px 0;
        //    width: 100%;
        //  }
        //  .SM-products_title {
        //    overflow: hidden;
        //    p {
        //      overflow: hidden;
        //      text-overflow: ellipsis;
        //      white-space: nowrap;
        //    }
        //  }
        //  .SM-products_shopName {
        //    font-size: 12px;
        //    color: #8B8B8B;
        //    letter-spacing: 1px;
        //    div {
        //      &:nth-child(1) {
        //        color: #8E8E8E;
        //        &:before {
        //          display: inline-block;
        //          content: "";
        //          background-image: url("/wp-content/themes/suimin-v1/img/common/icon_external_link.png");
        //          background-size: 12px 12px;
        //          background-repeat: no-repeat;
        //          width: 12px;
        //          height: 12px;
        //          margin-right: 3px;
        //          vertical-align: -1px;
        //        }
        //      }
        //      &:nth-child(2) {
        //        color: #000000;
        //      }
        //    }
        //  }
        //  .SM-products_price {
        //    font-size: 12px;
        //    font-family: Noto Sans CJK JP;
        //  }
        //}
        &:hover {
          .SM-products_img {
            img {
              opacity: 0.7;
            }
          }

        }
      }
      .PM-products_mm {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        //top: 15px;
        //right: 15px;
        @include vwtab(15, top);
        @include vwtab(15, right);
        //font-size: 10px;
        @include vwtab(10);
        color: #B69972;
        //width: 55px;
        //@include vwtab(66, width);
        //height: 14px;
        @include vwtab((2, 4), padding);
        //@include vwtab(14, height);
        //border-radius: 2px;
        @include vwtab(2, border-radius);
        background-color: #FFFFFF;
        text-align: center;
        //letter-spacing: 2px;
        @include vwtab(2, letter-spacing);
      }
    }
  }
}
@include mq() {
  .SM-product_tile {
      width: 994px;
      padding: 0;
    li {
        width: 33.3333%;
      a {
        div {
          &.SM-products_img {
            > div {
                padding: 30px 26px 22px;
            }
            img {
              object-fit: contain;
              transition: 0.4s;

              //position: absolute;
              //top: 0;
              //left: 0;
            }
          }
        }
      }
      .PM-products_mm {
          font-size: 11px;
          //width: 69px;
        padding: 2px 4px;
          //height: 21px;
          top: 12px;
          right: 12px;
          border-radius: 4px;
          letter-spacing: 3px;
          color: #A78455;
      }
    }
  }
}
