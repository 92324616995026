  .SM-stt_pcCategoryNav_motif {
        .SM-categoryNav_motif {
          &:after {
            transform: rotate(-180deg);
          }
        }
  }
  .SM-stt_pcCategoryNav_item {
      .SM-categoryNav_item {
        &:after {
          transform: rotate(-180deg);
        }
    }
  }
  .SM-stt_pcCategoryNav_neko {
      .SM-categoryNav_neko {
        &:after {
          transform: rotate(-180deg);
        }
    }
  }

.SM-menuNav {
  dl {
    margin: 0;
  }
  dt {
    height: 60px;
    font-size: 14px;
    font-weight: normal;
    border-top: 1px solid #F4F1ED;
    line-height: 1;
    margin-top: 0;

    a {
      position: relative;

      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      padding: 0 16px;
      &:after {
        content: "";
        background-image: url("/wp-content/themes/suimin-v1/img/common/category_menu_arrow_sp.svg");
        background-size: 12px 8px;
        width: 12px;
        height: 8px;
        position: absolute;
        top: 25px;
        right: 20px;
        transition: transform .3s;
      }
      @include mq() {
        &:after {
          display: none;
        }
      }
    }
  }
  dd {
    display: none;
    background-color: #F4F1ED;
    margin: 0;
    line-height: 1;
    ul {
      display: flex;
      flex-wrap: wrap;
      padding: 8px 0;
      li {
        width: 50%;
        margin: 7.5px 0;
        padding: 0 16px;
        a {
          display: flex;
          align-items: center;
          > img {
            @include vw(48, width);
          }
          > p {
            padding-left: 8px;
          }
        }

      }
    }
  }
}

@include mq() {
  .SM-menuNav {
    dl {
    }
    dt {
      height: 80px;
      a {
      }
    }
    dd {
      transition: .5s box-shadow;
      //box-shadow: 0px 32px 30px 0px rgba(0, 0, 0, 0.16);
      ul {
        padding: 4px 0 30px;
        li {
          margin: 0;
          padding: 0;
          a {
            flex-direction: column;
            > img {
            }
            > p {
              padding: 3px 0 0;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  body.SM-stt_pcCategoryNav {
    .SM-menuNav {
      dd {
        box-shadow: 0px 32px 30px 0px rgba(0, 0, 0, 0.16);
      }
      &.SM-stt_pcCategoryNav_motif {
        .SM-categoryNav_motif {
          color: #946C37;
          font-weight: 700;
        }
      }
      &.SM-stt_pcCategoryNav_item {
        .SM-categoryNav_item {
          color: #946C37;
          font-weight: 700;
        }
      }
      &.SM-stt_pcCategoryNav_neko {
        .SM-categoryNav_neko {
          color: #946C37;
          font-weight: 700;
        }
      }
    }
  }
}