.SM-404, .SM-search404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .SM-sorry_btns {
    display: flex;
    flex-direction: column;
    a {
      margin: 20px 20px;
    }
  }
}


@include mq() {
  .SM-404, .SM-search404 {
    padding-bottom: 100px;
    .SM-sorry_btns {
      display: flex;
      flex-direction: row;
      a {
        margin: 0 20px;
      }
    }
  }
}

.SM-sorry {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 50px;
  width: 100%;
  > p {
    font-size: 14px;
    padding-top: 20px;
  }
  > img {
    width: 100px;
  }
}


