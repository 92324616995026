.SM-searchHeader {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px 16px 0;
  font-size: 14px;
  font-weight: 400;
  //.SM-categoryPath {
  //  ul {
  //    display: flex;
  //  }
  //}
  .SM-searchNekoItem {
    position: relative;
    width: 240px;
    margin: 32px 0 10px;
    border-bottom: 1px solid #000000;
    &:after {
      position: absolute;
      top: 9px;
      right: 7px;
      content: "";
      background-image: url("/wp-content/themes/suimin-v1/img/common/select_arrow.svg");
      background-size: 10px 4.5px;
      width: 10px;
      height: 4.5px;
    }
    select {
      width: 100%;
      font-size: 14px;
      background-color: transparent;
    }
  }
}

@include mq() {
  .SM-searchHeader {
    flex-direction: row;
    justify-content: space-between;
    width: 980px;
    padding: 48px 0 20px;
    .SM-searchNekoItem {
      margin: 0;
      width: 340px;
      select {
        font-size: 14px;
      }
    }
  }
}