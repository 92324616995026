.SM-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #DED1C0;
  //@include vw(50, margin-top);
  padding: 36px 0 57px;
  .SM-footer_logo {
    width: 136px;
  }
  .SM-footer_snsLink {
    display: flex;
    padding-top: 13px;
    .SM-footer_snsLink_instagram {
      display: block;
      width: 29px;
    }
    .SM-footer_snsLink_twitter {
      display: block;
      width: 35px;
      margin-left: 24px;
    }
  }
  .SM-footer_copyright {
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 12px;
    color: #676767;
    padding-top: 20px;
    > span {
      font-size: 20px;
      vertical-align: -3px;
    }

  }
}

@include mq() {
  .SM-footerCategoryNav {
    display: none;
  }
}