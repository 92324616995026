@include mq() {
  .SM-stt_header_off {
    .SM-drawerMenu {
      top: 0;
    }
  }
}

.SM-drawerMenu {
  top: 64px;
  @include mq() {
    top: 115px;


    transition: top .5s;
    position: fixed;
    width: 100%;
  }
  .SM-l_drawerMenu {
    width: 100%;
    margin: 0;
  }
  .SM-drawerMenu_static {
    li {
      display: flex;
      height: 60px;
      border-color: #F4F1ED;
      a {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: normal;
        text-decoration: none;
        padding: 0 16px;
        color: #000000;
      }
    }
  }
  @include mq() {
    //display: flex !important;

    //transition: .5s height;

    left: auto;
    right: auto;
    position: fixed;
    overflow: hidden;
    //height: 400px;
    height: 80px;
    width: 100%;
    justify-content: center;
    background-color: transparent !important;
    //.SM-stt_header_off {
    //  .SM-drawerCategoryNav {
    //    top: 0;
    //  }
    //}
    .SM-drawerCategoryNav {
      display: flex;
      justify-content: center;
      background-color: #F4F1ED;

      //transition: top .5s;
      //position: fixed;
      //top: 115px;
      //width: 100%;
    }
    .SM-menuNav {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 80px;
      > div {
        width: 980px;
      }

    }
    .SM-l_drawerMenu {
      overflow: hidden;
    }
    > div.menu-modal-inner {
      opacity: 1;
      width: 100%;
      background-color: transparent;
      transform: none;
      > .menu-wrapper {
        background-color: transparent;
      }
      > div.SM-l_drawerMenu {
        width: 100%;
      }
    }
    dl {
      display: flex;
      width: 100%;
      dt {
        position: relative;
        width: 326.6666px;
        a {
          justify-content: center;
          //font-weight: 700;
          letter-spacing: 4px;
        }
        &:nth-child(-n+4) {
          &:after {
            position: absolute;
            top: 28px;
            right: 0;
            content: "";
            height: 24px;
            border-left: 1px solid #946C37;
          }
        }

      }
      dd {
        //display: flex;
        width: 100%;
        left: 0;
        justify-content: center;
        position: absolute;
        top: 80px;
        ul {
          margin: 0 auto;
          width: 980px;
        }
        li {
          width: 93px !important;
          margin: 20px 7.7px !important;
        }
      }
    }
  }
}


@include mq() {
  body.page-all, body.category, body.search-results {
    .SM-drawerMenu {
      display: flex !important;
    }
  }
  body.SM-stt_pcCategoryNav {
    .SM-drawerMenu {
      height: 100%;
    }
  }
  body.SM-stt_pcCategoryNav_motif {
    .SM-categoryNav_motif {
      color: #946c37;
    }
  }
  body.SM-stt_pcCategoryNav_item {
    .SM-categoryNav_item {
      color: #946c37;
    }
  }
  body.SM-stt_pcCategoryNav_neko {
    .SM-categoryNav_neko {
      color: #946c37;
    }
  }
}

