.SM-handmade {
  .SM-main {
    .entry-header {
      display: none;
    }
    .SM-handmadeMain_kv {
      display: flex;
      justify-content: center;
      overflow: hidden;
      //height: 65vw;
      width: 100%;
      //height: 243px;
      @include vw(176, height);
      @include mq() {
        //height: 512px;
        height: 35vw;
        max-height: 650px;
      }
      > img {
        @include vw(260, height);
        //transform: translate(getVW(10), getVW(5));
        max-width: none;
        @include mq() {
          //height: 512px;
          //transform: scale(1.82);
          //transform: translate(0, -3vw);
          width: 100%;
          height: auto;
        }
      }
    }
    //0.26
    //.SM-topMain_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      //padding-top: 40px;

      //padding-top: 50px;
      .SM-sec {
        //display: flex;
        flex-direction: column;
        //align-items: center;
        width: 100%;
        @include vw((56, 16, 0), padding);
        @include mq() {
          padding: 80px 0;
        }

        &.SM-handmade_main {
          > h2 {
            //width: 138px;
            //width: 35.88vw;
            @include vw(168, width);
            line-height: 1;
            font-size: 0;
            margin: 0;
            @include mq() {
              width: 225px;
            }
          }
          .SM-handmade_txt {
            font-size: 14px;
            line-height: 2;
            font-family: 'Noto Sans JP';
            @include vw((33, 0, 0), padding);
            p {
              padding: 20px 0;
            }
            @include mq() {
              width: 652px;
              text-align: center;
              padding: 53px 0 0;
            }
          }
          @include mq() {
            padding-bottom: 100px;
          }
        }
        &.SM-handmade_sns {
          > h2 {
            @include vw(93, width);
            line-height: 1;
            font-size: 0;
            margin: 0;
            @include mq() {
              width: 123px;
            }
          }
          ul {
            li {
              display: flex;
              flex-direction: column;
              align-items: center;
              padding-top: 47px;
              > div {
                @include vw(70, width);
                @include vw(70, height);
                @include mq() {
                  width: 70px;
                  height: 70px;
                }
              }
              > h3 {
                display: flex;
                font-size: 20px;
                letter-spacing: 0.3em;
                padding-top: 20px;
                svg {
                  margin-right: 10px;
                }
              }
              > p {
                font-size: 14px;
                color: #aeaeae;
                letter-spacing: 0.1em;
                padding-top: 8px;
              }
              > a {
                margin-top: 20px;
              }
              //&:nth-child(1) {
              //  > h3 {
              //    &:before {
              //      content: "";
              //
              //    }
              //  }
              //}
            }
            @include mq() {
              display: flex;
              padding-top: 72px;
              li {
                justify-content: space-between;
                padding: 0 36px 0;
              }
            }
          }
          @include mq() {
            padding-bottom: 0;
          }
        }
        &.SM-sec_news {
          .post-inner {
            padding-top: 0;
          }
          @include vw((56, 0, 48), padding);
          h2 {
            @include vw(138, width);
          }
          > article {
            width: 100%;
            @include vw(32, padding-top);
          }
          .entry-content {
            > p {
              display: none;
            }
          }
          position: relative;
          @include vw(64, margin-top);
          background-color: #F4F1ED;
          //z-index: -2;
          .SM-sbi_footer {
            width: 100%;
            padding: 0 16px 48px;
            > div {
              display: flex;
              justify-content: center;
              background-color: #ffffff;
              padding-bottom: 36px;
              .SM-btn_link {
                margin: 0;
              }
            }
            @include mq() {
              width: 980px;
            }
          }
          @include mq() {
            margin-top: 0;
            padding-top: 0;
            > article {
              width: 980px;
              padding-top: 96px;
            }
            > h2 {
              position: absolute;
              width: 184px;
              top: 160px;
              z-index: 1;
            }
          }
        }

        &.SM-handmade_instagram {
          @include vw((80, 0, 0), padding);
          ul {
            display: flex;
            flex-wrap: wrap;
            li {
              width: 33.3333%;
              &:last-child {
                display: none;
              }
              a {
                display: block;
                transition: opacity 0.7s;
                &:hover {
                  opacity: 0.7;
                }
              }
              @include mq() {
                width: 20%;
                &:last-child {
                  display: list-item;
                }
              }
            }
          }
          @include mq() {
            width: 100%;
            max-width: 1440px;
            padding-top: 120px;
          }
        }
      }
      .SM-topMain_categoryList {
        display: flex;
        flex-direction: column;
        align-items: center;
        a {
          display: block;
          position: relative;

          @include vw(240, width);

          span {
            position: absolute;
            bottom: 0;
            margin: auto;
            left: 0;
            right: 0;
            text-align: center;
            font-size: 16px;
            @include vw(14);
            //font-family: Noto Sans CJK JP;
            font-weight: bold;
          }


          &:hover {
            img {
              &:nth-child(1) {
                opacity: 1;
                transition: 0.2s opacity;
                z-index: 1;

              }
              &:nth-child(2) {
                opacity: 0;
                //transition-delay: 0s;
                transition: 0.6s opacity;
                z-index: 2;

              }
            }
          }

          img {
            position: absolute;
            top: 0;
            left: 0;
            &:nth-child(1) {
              opacity: 0;
              transition: 0.6s opacity;
              z-index: 2;
            }
            &:nth-child(2) {
              transition: 0.2s opacity;
              z-index: 1;

            }

          }

          &:not(:first-child) {
            @include vw(20, padding-top);
          }

          &:nth-child(1) {
            //width: 240px;
            @include vw(240, width);
            @include vw(25, margin-top);
            @include vw(230, height);



          }
          &:nth-child(2) {
            //width: 240px;
            @include vw(223, width);

            @include vw(211, height);

          }
          &:nth-child(3) {
            //width: 240px;
            @include vw(213, width);

            @include vw(211, height);

          }
        }
        @include mq() {
          flex-direction: row;
          padding-top: 45px;
          a {
            width: 326px;
            height: 310px;

            &:nth-child(1) {
              width: 326px;
              //height: 279px;
              height: 310px;
              padding-top: 0;

            }

            &:nth-child(2) {
              width: 326px;
              height: 310px;

              padding-top: 0;

            }

            &:nth-child(3) {
              width: 326px;
              height: 310px;

              padding-top: 0;

            }

            span {
              font-size: 16px;
            }
          }
        }
      }
    //}
    .SM-btn_link {
      @include vw(32, margin-top);
    }
  }
}

@import "insta_feed";