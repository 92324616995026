$pageImgPath: "../";
$commonImgPath: "/tousan_sougu/ws-web/v01/pub/img/";
$fontPath: "/tousan_sougu/ws-web/v01/pub/font/";
@import './util/util';
@import './util/breakpoint';
@import './util/reset';
@import './common/base';
@import './common/header';
@import './common/drawer_menu';
@import './common/footer';
@import './common/parts';
@import './common/products';
@import './common/searchList';
@import './common/search_header';
@import './common/category_menu_nav';
@import './main';
@import './404';
@import './category_main';
@import './top';
@import './about';
@import './contact';
@import './handmade';
@import url(https://fonts.googleapis.com/earlyaccess/notosansjp.css);

//body {
//  background-color: red !important;
//}