html {
  font-family: 游ゴシック体, YuGothic, 游ゴシック, "Yu Gothic", メイリオ, sans-serif !important;
  body {
    background: #ffffff;
    min-width: 300px;
    color: #000000;
    a {
      text-decoration: none;
      color: #000000;
    }
    li {
      list-style: none;
    }
  }
}



.SM-contentArea {
  padding-top: 64px;
  margin-top: 0;
  .SM-main {
    > section {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: "Noto Sans JP";
      &:last-child {
        @include vw(50, padding-bottom);
      }
    }
  }
}

@include mq() {
  .SM-contentArea {
    padding-top: 115px;
    .SM-main {
      > section {
        &:last-child {
          padding-bottom: 64px;
        }
      }
    }
  }
}

.wpcf7-not-valid-tip {
  color: #C22727 !important;
}