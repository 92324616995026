input,
button,
textarea,
select {
  /* デフォルトスタイルをリセット */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  /* font-familyを継承しないので、継承させる */
  font-family: inherit;

  /* iOSの角丸をリセット */
  border-radius: 0;

  /* box-size */
  box-sizing: border-box;

  /* 文字の大きさ iOSでズームさせないために16px以上を指定 */
  font-size: 16px;

  /* 文字色を親から継承 */
  color: inherit;

  border: 0;
  &:focus {
    outline: none;
  }
}

label {
  /* iOSでのlabelとinput,select,textareaの関連付け */
  cursor: pointer;
}

/* スピンボタン非表示 chrome,safari */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button,
input[type="month"]::-webkit-outer-spin-button,
input[type="month"]::-webkit-inner-spin-button,
input[type="datetime-local"]::-webkit-outer-spin-button,
input[type="datetime-local"]::-webkit-inner-spin-button,
input[type="week"]::-webkit-outer-spin-button,
input[type="week"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button,
input[type="time"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-inner-spin-button {
  /*-webkit-appearance: none;
  margin: 0;*/
  display: none;
}

/* スピンボタン非表示(firefox) */
input[type="number"],
input[type="month"],
input[type="datetime-local"],
input[type="week"],
input[type="time"],
input[type="date"] {
  -moz-appearance: textfield;
}

/* クリアボタン非表示 */
input[type="date"]::-webkit-clear-button,
input[type="month"]::-webkit-clear-button,
input[type="datetime-local"]::-webkit-clear-button,
input[type="time"]::-webkit-clear-button,
input[type="week"]::-webkit-clear-button {
  -webkit-appearance: none;
}

h1, h2, h3, h4 {
  font-weight: normal;
}